import React, { useState } from "react";
import styled from "styled-components";
import { Modal, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { file } from "@babel/types";
import styles from "./UploadModal.module.scss";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";

const { Dragger } = Upload;

export const UploadModal = ({
  visible,
  onCancel,
  stateKey,
  importFile,
  isCsv = false,
  params,
}: any) => {
  const dispatch = useDispatch();
  const isImportLoading = useSelector(
    (state: any) => state[stateKey]
  ).importLoading;

  const [open, setOpen] = useState(false);
  const [fileOptions, seFileOptions] = useState();
  const [messageText, setMessageText] = useState("");
  const handleChanges = (options: any) => {
    setMessageText("");
    const format = options?.file?.name?.split(".").pop();
    if (isCsv ? format === "csv" : format === "xlsx") {
      if (options?.file?.status !== "removed") {
        seFileOptions(options.file);
        setOpen(true);
      }
    } else {
      showErrorNotification(
        "Incorrect file format. Only " +
          (isCsv ? ".csv" : ".xlsx") +
          " files are supported."
      );
    }
  };

  const handleOk = () => {
    dispatch(importFile({ file: fileOptions, params: params }));
    setOpen(false);
    setTimeout(() => onCancel(), 10);
  };

  const handleCancel = () => {
    setOpen(false);
    setTimeout(() => onCancel(), 10);
  };

  const handleClose = () => {
    setMessageText("");
    onCancel();
  };

  return (
    <StyledImportModal
      title="Upload File"
      visible={visible}
      onCancel={handleClose}
      footer={null}
      centered
    >
      <Dragger
        disabled={isImportLoading}
        accept={".xlsx"}
        maxCount={1}
        beforeUpload={() => false}
        fileList={[]}
        onChange={handleChanges}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        {isImportLoading ? (
          <p className="ant-upload-hint">Processing...</p>
        ) : (
          <>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">Support for a single file only</p>
            {/* <span className={styles["ant-upload-error"]}>{messageText}</span> */}
          </>
        )}
      </Dragger>
      <Modal
        title="Upload Confirmation"
        okText="Ok"
        centered
        style={{ top: 20 }}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <span>Are you sure you want to upload this file?</span>
      </Modal>
    </StyledImportModal>
  );
};

const StyledImportModal = styled(Modal)`
  .ant-modal-content {
    width: initial;
  }
`;
