import { call, put } from "redux-saga/effects";
import { fetchAccessoryReplenishmentSuccess } from "./actions";
import { default as api } from "../../util/api";
import { showErrorNotification } from "../../util/notifications";
import {
  ACCESSORY_REPLENISHMENT_URL,
  PRINT_TAGS_URL,
} from "../../util/constants/api.constants";
import { toQueryParam } from "../../util/utils";

export function* fetchAccessoryReplenishment({ payload }: any): Generator {
  try {
    let url = ACCESSORY_REPLENISHMENT_URL.ACCESSORY_REPLENISHMENT_LIST;
    const data: any = yield call(api.post, url, payload);
    yield put(fetchAccessoryReplenishmentSuccess(data));
  } catch (e: any) {
    showErrorNotification(null, e);
  }
}

export function saveAccessoryReplenishmentItems(payload: any) {
  let url = `${ACCESSORY_REPLENISHMENT_URL.PREFERENCES}`;
  return api.post(url, payload);
}

export function updateAccessoryReplenishmentItems(payload: any) {
  let url = `${ACCESSORY_REPLENISHMENT_URL.PREFERENCES}`;
  return api.put(url, payload);
}
export function deleteAccessoryReplenishmentItems(payload: any) {
  let url = `${ACCESSORY_REPLENISHMENT_URL.PREFERENCES}`;
  return api.delete(url, payload);
}

export function getProductCategory(payload: any) {
  let url = `${ACCESSORY_REPLENISHMENT_URL.GET_PRODUCT_CATEGORY_LIST}`;
  return api.get(url);
}
export function getProduct(payload: any) {
  let url = ACCESSORY_REPLENISHMENT_URL.GET_PRODUCT_LIST;
  return api.post(url, payload);
}

export function getPreferencesData(payload: any) {
  let url = ACCESSORY_REPLENISHMENT_URL.ACCESSORY_REPLENISHMENT_LIST;
  return api.post(url, payload);
}

export function getOffices(payload: any) {
  let url = `${PRINT_TAGS_URL.OFFICES}`;
  return api.get(url);
}
