import { Divider, Form, Modal, Spin } from "antd";
import TableFilter from "src/components/common/TableFilter/TableFilter";
import Breadcrumb from "src/components/common/Breadcrumb/Breadcrumb";
import Button from "src/components/common/Button/Button";
import { useEffect, useState } from "react";
import { DATE_FORMAT } from "src/util/columns";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import GenericTable from "../common/Table/GenericTable";
import filterColumns from "./filterColumns";
import FedExForm from "./FedExForm";
import { MODULE_KEY, fetchFedEx } from "./actions";
import {
  getShippingButtonStatus,
  reprintFedExBarcode,
  saveShippingButtonStatus,
  printEndOfDayReport
} from "./sagas";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import PDFStream from "../common/PDFStream/PDFStream";

function FedEx() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { accounts }: any = useMsal();
  const [shippingStatus, setShippingStatus] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  let memoizeDate = moment().format(DATE_FORMAT);
  const [shippingDate, setShippingDate] = useState<any>(memoizeDate);
  const [shippingOpenDate, setShippingOpenDate] = useState<any>(memoizeDate);
  const [stream, setStream] = useState<any>("");
  
  const reprint = (record: any) => {
    reprintFedExBarcode({ TransactionId: record.TransactionId })
      .then((res: any) => {
        showSuccessNotification(res);
      })
      .catch((error: any) => {
        showErrorNotification(error?.response?.data?.error?.messages[0]);
      });
  };
  let filterValues = filterColumns(reprint);
  useEffect(() => {
    getDailyShippingDetails();
  }, []);

  useEffect(() => {
    if (shippingDate != "Invalid date")
      dispatch(fetchFedEx(shippingDate.substring(0, 10)));
  }, [shippingDate]);

  const getDailyShippingDetails = () => {
    getShippingButtonStatus()
      .then((res: any) => {
        setShippingStatus(res?.IsOpen);
        setShippingOpenDate(res?.OpenShipmentDate);
        setShippingDate(res?.IsOpen ? res?.OpenShipmentDate : shippingDate);
        form.setFieldValue(
          "shippingDate",
          res?.IsOpen ? moment(res?.OpenShipmentDate) : moment(shippingDate)
        );
      })
      .catch((error: any) => {
        showErrorNotification(error?.response?.data?.error?.messages[0]);
      });
  };

  const getEndOfDayRecord = () => {
    setConfirmLoading(true);
    const reportPayload = {
      "reportName": "DCEndOfDaySlip",
      "parameters": { "ShipDate": shippingDate }
    }
      
    printEndOfDayReport(reportPayload).then((result: string) => {
      setConfirmLoading(false);
      if (result) {
        setStream(result);
      }
    }).catch((error: any) => {
      showErrorNotification("Problem occured while getting end of day record. Please contact support");
    })
  }

  const handleShippingButtons = (ButtonType: any) => {
    const status = ButtonType === "open" ? true : false;
    const payload = {
      ShippingDate: shippingDate,
      IsOpen: status,
      UserName: accounts[0]?.username,
    };
    saveShippingButtonStatus(payload)
      .then((res: any) => {
        showSuccessNotification(res);
        getDailyShippingDetails();
      })
      .catch((error: any) => {
        showErrorNotification(error?.response?.data?.error?.messages[0]);
      });
  };
  
  return (
    <>
      <Spin spinning={confirmLoading}>
        <Divider />
        <Breadcrumb>
          <span>Fed Ex Shipping Label Utility</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end flex"></div>
          <div className="d-flex justify-content-end">
            <Button
              size="middle"
              style={{ marginRight: "10px" }}
              ant-click-animating-without-extra-node="true"
              disabled={
                !shippingDate ||
                shippingStatus ||
                moment(shippingDate).isBefore(moment(), "day") ||
                moment(shippingDate).isAfter(moment().add(1, "week"), "day")
              }
              onClick={() => handleShippingButtons("open")}
            >
              Open Daily Shipping
            </Button>
            <Button
              size="middle"
              style={{ marginRight: "10px" }}
              ant-click-animating-without-extra-node="true"
              onClick={() => handleShippingButtons("close")}
              disabled={!shippingDate || !shippingStatus}
            >
              Close Daily Shipping
            </Button>
            <Button
              size="middle"
              style={{ marginRight: "10px" }}
              ant-click-animating-without-extra-node="true"
              onClick={() => getEndOfDayRecord()}
              disabled={!shippingDate ||
                shippingStatus ||
                moment(shippingDate).isBefore(moment().add(-1, "day"), "day") ||
                moment(shippingDate).isAfter(moment().add(1, "week"), "day")}         >
              End of Day Record
            </Button>
          </div>
        </TableFilter>
        <FedExForm
          form={form}
          shippingDate={shippingDate}
          setShippingDate={setShippingDate}
          shippingStatus={shippingStatus}
          shippingOpenDate={shippingOpenDate}
          accounts={accounts}
          setConfirmLoading={setConfirmLoading}
        />
        <GenericTable
          rowkey="Id"
          columns={filterValues.columns}
          moduleKey={MODULE_KEY}
          loading={confirmLoading}
          xScroll={1000}
          yScroll={"calc(90vh - 17.5em)"}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100"],
          }}
        />
        {stream ? <Modal
          visible={true}
          onOk={() => setStream("")}
          onCancel={() => setStream("")}
          footer={null}
          width={1000}
          destroyOnClose
        >
          <PDFStream onPrint={() => setStream("")} stream={stream} fileName="EndOfDaySlip"/>
        </Modal> : null}
      </Spin>
    </>
  );
}
export default FedEx;
