import { DatePicker, Divider, Table } from 'antd';
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Notification } from '../../util/notification';
import { formatDate, GenericObject } from '../../util/utils';
import { fetchOpenPullList } from './openPull-actions';
import { MODULE_KEY } from './openPull-actions';
import { loadOrder } from './openPull-sagas';
import './../../styles/OpenPull.scss'
import useTableProps from '../../hooks/table/useTableProps';
import { OPEN_PULL_COLUMN, OPEN_PULL_DATA_TYPE } from '../../util/columns';
import moment from 'moment';
import TableFilter from '../common/TableFilter/TableFilter';
import Button from '../common/Button/Button';
import Breadcrumb from '../common/Breadcrumb/Breadcrumb';
import GenericTable from '../common/Table/GenericTable';
import filterColumns from './filterColumns';

// type ColorColumnType = {
//   title: string
//   dataIndex: string
//   render: (color: string) => number
// };

//const { success, error } = new Notification("open pull");
let memoizeStartDate = moment().subtract(1, 'days').format("MM/DD/YYYY")
let memoizeEndDate = moment().subtract(1, 'days').format("MM/DD/YYYY")
function OpenPull() {
  const dispatch = useDispatch();
  const tableProps = useTableProps(MODULE_KEY, ["rowSelection"]);
  const { dataSource, loading } = tableProps;
  const [loader, setLoader] = useState(false)
  const [dateRange, setDateRange] = useState({ startDate: memoizeStartDate, endDate: memoizeEndDate })
  let filterValues = filterColumns();
  const tagColumns = useMemo(() => filterValues.columns, [dataSource])

  const startDateChange = (date: any) => {
    if (!date) {
      setDateRange({ endDate: date, startDate: date })
    } else {
      memoizeStartDate = date;
      setDateRange({ ...dateRange, startDate: date })
    }
  }
  const endDateChange = (date: any) => {
    memoizeEndDate = date;
    setDateRange({ ...dateRange, endDate: date })
  }

  const onLoad = () => {
    const payload = {
      startDate: formatDate(dateRange.startDate),
      endDate: formatDate(dateRange.endDate),
      offset: 0,
      limit: 100000
    }
    dispatch(fetchOpenPullList(payload))
  }

  const spinning = loading || loader;
  const { startDate, endDate } :any = dateRange;
  return (
    <div>
      <Divider />
      <Breadcrumb>
        <span >Open Pull</span>
      </Breadcrumb>
      <TableFilter>
      <div className='form align-items-end'>
        <DatePicker  value={startDate && moment(startDate)} disabled={spinning} disabledDate={d => !d || d.isAfter(moment()) || moment().add(-365, 'days')  >= d} placeholder='Start Date' onChange={startDateChange} />
        <DatePicker  value={endDate && moment(endDate)} disabled={spinning || !startDate} disabledDate={d => !d || d.isAfter(moment()) || d.isBefore(startDate)} placeholder='End Date' onChange={endDateChange} />

        <Button
          disabled={!(dateRange.startDate && dateRange.endDate) || spinning}
          onClick={onLoad}
          size="middle"
        >
          Load Data
        </Button>
      </div>
      </TableFilter>
      <GenericTable
        columns={tagColumns}
        loading={spinning}
        rowkey=""
        rowSelection={false}
        moduleKey={MODULE_KEY} 
        expandable 
        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
        xScroll={2000}
        yScroll={'calc(90vh - 17.5em)'}   
      />
    </div>
  )
}

export default OpenPull