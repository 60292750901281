import {
  Card,
  Form,
  Input,
  Drawer,
  Space,
  Spin,
  Select,
  Checkbox,
  DatePicker,
  DatePickerProps,
  Descriptions,
  Table,
  Modal,
} from "antd";
import { useEffect, useState } from "react";

import {
  fetchAssortmentCount,
  fetchAssortmentSku,
  fetchSKUDetails,
  getDatabaseCollection,
  getFrameType,
  saveFrameArrangerQty,
  updateFrameArrangerQty,
  validateAssortmentSku,
} from "./sagas";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import Button from "src/components/common/Button/Button";
import { DATE_FORMAT } from "src/util/columns";
import moment from "moment";
import GenericTable from "src/components/common/Table/GenericTable";
import {
  ASSORTMENT_SKU_MODULE_KEY,
  importSKUFile,
  MODULE_KEY,
} from "./actions";
import TextArea from "antd/lib/input/TextArea";
import { UploadModal } from "src/components/common/Uploads/UploadModal";
import { ACTION } from "src/util/utils";
import { useSelector } from "react-redux";

function AddFAQty({
  visible,
  setVisibleStatus,
  onLoad,
  data,
  setData,
  setConfirmLoading,
  frameDetailForm,
  filterValues,
  confirmLoading,
  skuData,
  setSkuData,
  actionType,
  accounts,
}: any) {
  const { Option } = Select;
  const [vendors, setVendors] = useState([]);
  const [frameType, setFrameType] = useState([]);
  const [startDate, setStartDate] = useState<any>("");
  const [stopDate, setStopDate] = useState<any>("");
  const [skuList, setSkuList] = useState<any>([]);
  const [skuModal, setSkuModal] = useState(false);
  const [fAQty, setFAQty] = useState<any>(0);
  const [assortmentCount, setAssortmentCount] = useState<any>(0);
  const [openSkuUpload, setOpenSkuUpload] = useState<boolean>(false);
  const [dbColletion, setDBCollection] = useState<any>();
  const stateKey = `${ASSORTMENT_SKU_MODULE_KEY}${ACTION.STATE_KEY}`;
  let importData = useSelector(
    (state: any) => state[stateKey]
  ).importValidationData;

  useEffect(() => {
    if (Object.keys(data).length) {
      const editFieldObj = {
        AssortmentId: data?.AssortmentId,
        FrameArrangerQuantity: data?.ArrangerQty,
        FrameTypeID: data?.FrameTypeID,
        FrameTypeDescription: data?.FrameTypeDescription,
        AssortmentName:
          actionType == "Clone"
            ? "CLONE-" + data?.AssortmentName
            : data?.AssortmentName,
        CountperCollection: data?.CountperCollection,
        Active: data?.Active,
        DatabaseCollectionID:
          data.Vendor +
          "-" +
          data.Collection +
          " (" +
          data?.DatabaseCollection +
          ")",
        StartDate:
          actionType == "Clone"
            ? moment()
            : data?.StartDate !== null
            ? moment(data?.StartDate)
            : "",
        StopDate:
          data?.StopDate !== null && actionType !== "Clone"
            ? moment(data?.StopDate)
            : "",
        AssortmentCount: data?.AssortmentCount,
      };
      setStartDate(
        actionType == "Clone"
          ? moment()
          : data?.StartDate !== null
          ? moment(data?.StartDate)
          : ""
      );
      setStopDate(
        data?.StopDate !== null && actionType !== "Clone"
          ? moment(data?.StopDate)
          : ""
      );
      setAssortmentCount(data?.AssortmentCount);
      frameDetailForm.setFieldsValue(editFieldObj);
      handleSkuDetails(data?.DatabaseCollection);
      fetchSKUDetails(data?.AssortmentId)
        .then((res: any) => {
          setSkuData(res );
        })
        .catch((error: any) => {
          // showErrorNotification(error.response.data.error.messages[0]);
        });
    }
  }, [data]);
  useEffect(() => {
    Promise.all([getDatabaseCollection(), getFrameType({})]).then((res) => {
      const sortedVendors: any = res[0]?.sort((a: any, b: any) => {
        if (a.VendorName !== null) {
          return a.VendorName.localeCompare(b.VendorName);
        }
      });
      setVendors(sortedVendors);
      const sortedFrameType: any = res[1]?.sort((a: any, b: any) => {
        return a.Description.localeCompare(b.Description, "sv");
      });
      setFrameType(sortedFrameType);
    });
  }, []);
  useEffect(() => {
    setFAQty(skuData?.length);
    frameDetailForm.setFieldValue("FrameArrangerQuantity", skuData?.length);
  }, [skuData]);
  useEffect(() => {
    try {
      if (importData?.ValidUPCs?.length) {
        if (typeof importData !== "string") {
          const skuRecords = removeDuplicates(importData.ValidUPCs, skuData);
          setSkuData([...skuData, ...skuRecords]);
          showSuccessNotification("SKU(s) added successfully");
          setOpenSkuUpload(false);
        } else {
          showErrorNotification(importData);
        }
      }
    } catch (error: any) {
      showErrorNotification(error.response.data.error.messages[0]);
    }
  }, [importData]);
  const handleClose = () => {
    frameDetailForm.resetFields();
    setData({});
    setVisibleStatus(false);
    setSkuData([]);
    setFAQty(0);
    setSkuList("");
    setSkuModal(false);
    setAssortmentCount(0);
    setDBCollection({});
  };
  const handleOk = async () => {
    try {
      const row = (await frameDetailForm.validateFields()) as any;
      setConfirmLoading(true);
      const fields = frameDetailForm.getFieldsValue();
      let res;
      let payload: any = {
        ArrangerQty: fields.FrameArrangerQuantity,
        FrameTypeID: fields.FrameTypeID,
        AssortmentName: fields.AssortmentName,
        CountperCollection: fields.CountperCollection,
        Active: fields.Active,
        DatabaseCollection: Object.keys(data).length
          ? data.DatabaseCollection
          : fields.DatabaseCollectionID,
        DateAdded: startDate !== "Invalid date" ? startDate : "",
        StopDate: stopDate !== "Invalid date" ? stopDate : "",
        UPCs: Array.from(new Set(skuData?.map((item: any) => item.UPC))),
      };
      if (Object.keys(data).length && actionType !== "Clone") {
        payload = {
          ...payload,
          FrameArrangerID: data?.AssortmentId,
          UpdatedBy: accounts?.[0]?.username,
        };
        res = await updateFrameArrangerQty(payload);
      } else {
        payload = { ...payload, CreatedBy: accounts?.[0]?.username };
        res = await saveFrameArrangerQty(payload);
      }
      if (res) {
        onLoad();
        showSuccessNotification(res);
        handleClose();
      } else {
        showErrorNotification(res);
      }
      setConfirmLoading(false);
    } catch (error: any) {
      showErrorNotification(error.response.data.error.messages[0]);
      setConfirmLoading(false);
    }
  };
  const onSearch = (value: string) => {
    console.log("search:", value);
  };
  const openSKuModal = (type: any) => {
    if (Object.keys(dbColletion)?.length) {
      type === "add" ? setSkuModal(true) : setOpenSkuUpload(true);
    } else {
      showErrorNotification("Please select database collection id");
    }
  };
  const drawerTitle = Object.keys(data).length
    ? actionType == "Clone"
      ? "Clone Assortment"
      : "Edit Assortment"
    : "Add Assortment";
  const removeDuplicates = (firstArr: any, secArr: any) => {
    const filterValue = firstArr.filter((list: any) => {
      return !secArr.find((mList: any) => list.UPC === mList.UPC);
    });
    return filterValue;
  };
  const handleSKUList = async () => {
    if (skuList?.length > 0) {
      const payload = {
        Vendor: dbColletion?.VendorName,
        Collection: dbColletion?.CollectionName,
        UPCs: Array.from(new Set(skuList?.split("\n"))),
      };
      validateAssortmentSku(payload)
        .then((res: any) => {
          const skuRecords = removeDuplicates(res.ValidUPCs, skuData);
          setSkuData([...skuData, ...skuRecords]);
          showSuccessNotification("SKU(s) added successfully");
          setSkuModal(false);
          setSkuList("");
        })
        .catch((error: any) => {
          showErrorNotification(error.response.data.error.messages[0]);
        });
    } else {
      showErrorNotification("Please enter atleast one sku");
    }
  };
  const handleSkuDetails = (value: any) => {
    const dbCollectionDetails: any = vendors?.find(
      (item: any) => item.DatabaseCollectionID === value
    );
    setDBCollection(dbCollectionDetails);
    if (!Object.keys(data).length || actionType === "Clone") {
      fetchAssortmentCount(dbCollectionDetails?.DatabaseCollectionID)
        .then((res: any) => {
          frameDetailForm.setFieldValue("AssortmentCount", res);
          setAssortmentCount(res);
        })
        .catch((error: any) => {
          showErrorNotification(error.response.data.error.messages[0]);
        });
    }
  };
  return (
    <>
      <Drawer
        title={drawerTitle}
        placement="right"
        visible={visible}
        onClose={handleClose}
        width={800}
        closable={false}
        className="dc-drawer-panel"
        forceRender={true}
        extra={
          <Space className="d-flex justify-content-end">
            <Button onClick={handleOk}>Save</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </Space>
        }
      >
        <div className="">
          <Form
            form={frameDetailForm}
            initialValues={{ remember: false }}
            autoComplete="off"
            className="add-picker-form"
            layout="vertical"
            name="frameDetailForm"
            style={{
              backgroundColor: "#f3f3f3",
              borderRadius: "16px",
              marginRight: "24px",
              boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
              marginTop: "10px",
            }}
          >
            <Card>
              <div>
                <Form.Item
                  label="Database Collection ID"
                  name="DatabaseCollectionID"
                  className="label-text region-field"
                  rules={[
                    {
                      required: true,
                      message: "Please select database collection id",
                    },
                  ]}
                >
                  <Select
                    showSearch={true}
                    placeholder="Select Database Collection ID"
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    disabled={!!Object.keys(data).length}
                    filterOption={(input, option) => {
                      return option!.children?.[0] !== null
                        ? (option!.children?.[0] as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        : false;
                    }}
                    onChange={(val: any) => handleSkuDetails(val)}
                  >
                    {vendors?.map((item: any, index: any) => {
                      return (
                        <Option key={index} value={item?.DatabaseCollectionID}>
                          {item?.VendorName} - {item?.CollectionName} (
                          {item?.DatabaseCollectionID})
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Frame Type"
                  name="FrameTypeID"
                  className="label-text region-field"
                  rules={[
                    { required: true, message: "Please select frame type" },
                  ]}
                >
                  <Select
                    showSearch={true}
                    placeholder="Select Frame Type"
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    disabled={!!Object.keys(data).length}
                    filterOption={(input, option) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {frameType?.map((item: any, index) => {
                      return (
                        <Option key={index} value={item?.ID}>
                          {item?.Description}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Assortment Name"
                  name="AssortmentName"
                  className="label-text region-field"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Assortment Name",
                    },
                    {
                      pattern: new RegExp(/^[- a-zA-Z0-9]+$/),
                      message: `Assortment Name should be alphanumeric`,
                    },
                    {
                      max: 100,
                      message: "Assortment Name should not be greater than 100",
                    },
                  ]}
                >
                  <Input name="AssortmentName" />
                </Form.Item>
              </div>
            </Card>
            <Card>
              <Space className="d-flex justify-content-end mb-2">
                <Button onClick={() => openSKuModal("add")}>Add SKUs</Button>
                <Button onClick={() => openSKuModal("import")}>
                  Import SKUs
                </Button>
              </Space>
              <GenericTable
                rowkey="UPC"
                columns={filterValues?.skuColumns}
                moduleKey={ASSORTMENT_SKU_MODULE_KEY}
                loading={confirmLoading}
                dataSource={skuData}
                xScroll={1000}
                yScroll={"calc(50vh - 17.5em)"}
                pagination={false}
                hideTotal={true}
                removeLogo={true}
              />{" "}
            </Card>
            <Card>
              <div>
                <Form.Item
                  label="Assortment Quantity"
                  name="FrameArrangerQuantity"
                  className="label-text region-field"
                >
                  <Input
                    name="FrameArrangerQuantity"
                    disabled={true}
                    value={fAQty}
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Assortment Count"
                  name="AssortmentCount"
                  className="label-text region-field"
                >
                  <Input
                    name="AssortmentCount"
                    disabled={true}
                    value={assortmentCount}
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="DEPRECATED (Count per Collection)"
                  name="CountperCollection"
                  className="label-text region-field"
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Count per Collection should be numeric`,
                    },
                    () => ({
                      validator(rule, value) {
                        if (parseInt(value) == 0 || parseInt(value) > 10000) {
                          return Promise.reject(
                            "Please enter value between 1 to 10000"
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input name="CountperCollection" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  valuePropName="checked"
                  name="Active"
                  className="label-text region-field"
                >
                  <Checkbox name="Active" value={true}>
                    {" "}
                    Active{" "}
                  </Checkbox>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Assortment Start Date"
                  name="StartDate"
                  className="label-text region-field"
                >
                  <DatePicker
                    format={DATE_FORMAT}
                    name="StartDate"
                    value={startDate && moment(startDate)}
                    disabledDate={(d) => moment(stopDate)?.isBefore(d)}
                    placeholder="Select Date"
                    onChange={(value: DatePickerProps["value"]) =>
                      setStartDate(moment(value).format(DATE_FORMAT))
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Assortment Stop Date"
                  name="StopDate"
                  className="label-text region-field"
                >
                  <DatePicker
                    format={DATE_FORMAT}
                    name="StopDate"
                    value={stopDate && moment(stopDate)}
                    disabledDate={(d) => moment(startDate)?.isAfter(d)}
                    placeholder="Select Date"
                    onChange={(value: DatePickerProps["value"]) =>
                      setStopDate(moment(value).format(DATE_FORMAT))
                    }
                  />
                </Form.Item>
              </div>
            </Card>
          </Form>
        </div>
      </Drawer>
      <Modal
        title="SKUs in Assortment"
        okText="Ok"
        centered
        width={500}
        confirmLoading={confirmLoading}
        style={{ top: 20 }}
        open={skuModal}
        onOk={handleSKUList}
        onCancel={() => {
          setSkuModal(false);
          setSkuList([]);
        }}
      >
        <span>
          <TextArea
            onChange={(e) => setSkuList(e.target.value)}
            value={skuList}
            rows={9}
          ></TextArea>
        </span>
      </Modal>
      <UploadModal
        visible={openSkuUpload}
        onCancel={() => setOpenSkuUpload(false)}
        stateKey={stateKey}
        importFile={importSKUFile}
        params={dbColletion}
      />
    </>
  );
}

export default AddFAQty;
