import {
  Card,
  Form,
  Input,
  Drawer,
  Space,
  Select,
  Checkbox,
  Descriptions,
  DatePicker,
  DatePickerProps,
  Radio,
  Modal,
} from "antd";
import { useEffect, useState } from "react";
import {
  getCollectionType,
  getFrameCollection,
  saveOfficeCollectionMapping,
  updateOfficeCollectionMapping,
} from "./sagas";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import Button from "src/components/common/Button/Button";
import moment from "moment";
import { getOffices } from "src/components/systemPreference/sagas";
import { DATE_FORMAT } from "src/util/columns";
import TextArea from "antd/lib/input/TextArea";
import { validateOfficeList } from "src/components/kits/KitProcessing/sagas";

interface AddOCMappingType {
  visible: boolean;
  setVisibleStatus: any;
  onLoad: any;
  data: any;
  setData: any;
  frameCollection: any;
  confirmLoading: any;
  setConfirmLoading: any;
}

function AddOCMapping({
  visible,
  setVisibleStatus,
  onLoad,
  data,
  setData,
  frameCollection,
  confirmLoading,
  setConfirmLoading,
}: AddOCMappingType) {
  const { Option } = Select;
  const [frameDetailForm] = Form.useForm();
  const [officeModal, setOfficeModal] = useState(false);
  const [officeList, setOfficeList] = useState<any>([]);
  const [officeData, setOfficeData] = useState([]);
  const [collectionType, setCollectionType] = useState([]);
  const [FrameArrangerID, setFrameArrangerId] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [regionValue, setRegionValue] = useState([]);
  let memoizeDate = moment().format(DATE_FORMAT);
  const [officeSelectMode, setOfficeSelectMode] = useState("");
  const [updatedDate, setUpdatedDate] = useState<any>(memoizeDate);
  const [validatedOffices, setValidatedOffices] = useState<any>([]);
  const [stopDate, setStopDate] = useState<any>(memoizeDate);

  useEffect(() => {
    if (Object.keys(data).length) {
      const editFieldObj = {
        FrameArrangerID: data?.FrameArrangerID,
        CollectionType: data?.CollectionType,
        Offices: data?.LocationName + " (" + data?.OfficeNum + ")",
        Notes: data?.UpdateNotes,
        UpdatedDate: data.UpdatedDate !== null ? moment(data.UpdatedDate) : "",
        StopDate: data.StopDate != null ? moment(data.StopDate) : "",
      };
      frameDetailForm.setFieldsValue(editFieldObj);
      setUpdatedDate(data.UpdatedDate !== null ? moment(data.UpdatedDate) : "");
      setStopDate(data.StopDate != null ? moment(data.StopDate) : "");
    }
  }, [data]);

  useEffect(() => {
    if (frameCollection) {
      setFrameArrangerId(frameCollection);
    }
  }, [frameCollection]);

  useEffect(() => {
    const location: any = [];
    const region: any = [];
    Promise.all([
      getFrameCollection({}),
      getCollectionType(),
      getOffices({}),
    ]).then((res) => {
      const sortedFAId: any = res[0]?.sort((a: any, b: any) => {
        return a.Vendor !== null && a.Vendor.localeCompare(b.Vendor, "sv");
      });

      const sortedFC: any = frameCollection?.sort((a: any, b: any) => {
        return a.Vendor !== null && a.Vendor.localeCompare(b.Vendor, "sv");
      });

      setFrameArrangerId(Object.keys(data).length ? sortedFC : sortedFAId);
      const sortedCollectionType: any = res[1]?.sort((a: any, b: any) => {
        return a.Description.localeCompare(b.Description, "sv");
      });
      setCollectionType(sortedCollectionType);
      const sortedOffice: any = res[2]?.sort((a: any, b: any) => {
        return (
          a.LocationNumber !== null &&
          a.LocationNumber.localeCompare(b.LocationNumber, "en", {
            numeric: true,
          })
        );
      });
      setOfficeData(sortedOffice);
      sortedOffice.map((item: any) => {
        location.push({
          LocationNumber: item?.LocationNumber,
          LocationName: item?.LocationName,
        });
        if (!region.includes(item.Region)) {
          region.push(item.Region);
        }
      });
      const sortedRegion = region?.sort((a: any, b: any) => {
        return a?.localeCompare(b, "en", { numeric: true });
      });
      setLocationData(location);
      setRegionData(sortedRegion);
    });
  }, []);

  const handleClose = () => {
    setData([]);
    setVisibleStatus(false);
    //setRegionData([]);
    setDistrictData([]);
    frameDetailForm.resetFields();
  };

  const handleOk = async () => {
    try {
      const row = (await frameDetailForm.validateFields()) as any;
      const fields = frameDetailForm.getFieldsValue();
      let res;
      let payload: any = {
        FrameArrangerID: Object.keys(data).length
          ? fields?.FrameArrangerID
          : "0",
        CollectionType: fields?.CollectionType,
        UpdateNotes: fields?.Notes,
        UpdatedDate: updatedDate !== "Invalid date" ? updatedDate : "",
        StopDate: stopDate !== "Invalid date" ? stopDate : "",
        AssortmentIDs: Object.keys(data).length ? "" : fields?.FrameArrangerID,
      };
      if (Object.keys(data).length) {
        payload = {
          ...payload,
          ID: data?.ID,
          officeLocations: [
            {
              LocationName: data?.LocationName,
              OfficeNum: data?.OfficeNum,
            },
          ],
        };
        res = await updateOfficeCollectionMapping(payload);
      } else {
        let officeLocations: any = [];
        if (fields.Offices.includes("All")) {
          locationData.map((ele: any) => {
            officeLocations.push({
              LocationName: ele.LocationName,
              OfficeNum: ele.LocationNumber,
            });
          });
        } else {
          officeData.filter((ele: any, ind: any) => {
            fields?.Offices.some((elem: any) => {
              const officeNumber = elem;
              if (officeNumber === ele.LocationNumber) {
                officeLocations.push({
                  LocationName: ele.LocationName,
                  OfficeNum: ele.LocationNumber,
                });
              }
            });
          });
        }
        payload = {
          ...payload,
          officeLocations: officeLocations,
        };
        res = await saveOfficeCollectionMapping(payload);
      }
      if (res) {
        showSuccessNotification(res);
        handleClose();
        onLoad();
      } else {
        showErrorNotification(res);
      }
    } catch (error: any) {
      showErrorNotification(error.response.data.error.messages[0]);
    }
  };
  const handleAllData = async (key: any, value: any) => {
    switch (key) {
      case "office":
        if (value.length == 0) {
          setOfficeSelectMode("");
          setDistrictData([]);
          frameDetailForm.resetFields(["Offices", "District", "Region"]);
        }
        break;
      case "region":
        const districtLocation: any = [];
        const district: any = [];
        frameDetailForm.resetFields(["Offices", "District"]);
        setOfficeSelectMode("region");
        if (value.length > 0) {
          officeData?.map((item: any) => {
            value?.map((ele: any) => {
              if (ele === item.Region) {
                if (!district.includes(item.District)) {
                  district.push(item.District);
                }
                districtLocation.push({
                  LocationNumber: item?.LocationNumber,
                  LocationName: item?.LocationName,
                });
              }
            });
          });
          setRegionValue(value);
          const districtData = district?.sort((a: any, b: any) => {
            return a.localeCompare(b, "en", { numeric: true });
          });
          const sortedOffice = districtLocation?.sort((a: any, b: any) => {
            return (
              a.LocationNumber !== null &&
              a.LocationNumber.localeCompare(b.LocationNumber, "en", {
                numeric: true,
              })
            );
          });
          setDistrictData(districtData);
          setLocationData(sortedOffice);
        } else {
          frameDetailForm.setFieldsValue({ District: [] });
          setDistrictData([]);
          const loc: any = officeData?.map((item: any) => item.LocationNumber);
          setLocationData(loc);
          setOfficeSelectMode("");
        }
        break;
      case "district":
        const location: any = [];
        frameDetailForm.resetFields(["Offices"]);
        if (value.length > 0) {
          officeData?.map((item: any) => {
            value?.map((ele: any) => {
              if (ele === item.District) {
                if (!location.includes(item.LocationNumber)) {
                  location.push({
                    LocationNumber: item?.LocationNumber,
                    LocationName: item?.LocationName,
                  });
                }
              }
            });
          });
          const sortedOffice = location?.sort((a: any, b: any) => {
            return (
              a.LocationNumber !== null &&
              a.LocationNumber.localeCompare(b.LocationNumber, "en", {
                numeric: true,
              })
            );
          });
          setLocationData(sortedOffice);
        } else {
          officeData?.map((item: any) => {
            regionValue?.map((ele: any) => {
              if (ele === item.Region) {
                location.push({
                  LocationNumber: item?.LocationNumber,
                  LocationName: item?.LocationName,
                });
              }
            });
          });
          const sortedOffice = location?.sort((a: any, b: any) => {
            return (
              a.LocationNumber !== null &&
              a.LocationNumber.localeCompare(b.LocationNumber, "en", {
                numeric: true,
              })
            );
          });
          setLocationData(sortedOffice);
        }
        break;
      default:
      // code block
    }
  };
  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  const drawerTitle = Object.keys(data).length
    ? "Edit Office Collection Mapping"
    : "Add Office Collection Mapping";

  const handleOfficeList = () => {
    if (officeList?.length > 0) {
      validateOfficeList(officeList?.trim().split("\n"))
        .then((res: any) => {
          if (res?.length > 0) {
            setOfficeList([]);
            setValidatedOffices(res);
            const seletedOffices = res.map((item: any) => item.LocationNumber);
            frameDetailForm.setFieldValue("Offices", seletedOffices);
            setOfficeModal(false);
            showSuccessNotification("Offices added successfully");
          }
          setOfficeSelectMode("QuickEntry");
        })
        .catch((error: any) => {
          showErrorNotification(error.response.data?.error?.messages[0]);
        });
    } else {
      showErrorNotification("Please enter atleast one office");
    }
  };
  
  const AssortmentDropdownElement = () =>
    FrameArrangerID?.map((item: any, index) => {
      return (
        <Option
          key={index}
          value={
            Object.keys(data).length
              ? item?.FrameArrangerID
              : item?.AssortmentId
          }
          label={`${item?.Vendor}-${item?.Collection}-${
            item?.FrameTypeDescription
          }-(${
            Object.keys(data).length
              ? item?.FrameArrangerID
              : item?.AssortmentId
          })-QTY:${item?.ArrangerQty}`}
        >
          {item?.Vendor}-{item?.Collection}-{item?.FrameTypeDescription} (
          {Object.keys(data).length
            ? item?.FrameArrangerID
            : item?.AssortmentId}
          )- QTY:{item?.ArrangerQty}
        </Option>
      );
    });

  return (
    <>
      <Drawer
        title={drawerTitle}
        placement="right"
        visible={visible}
        onClose={handleClose}
        width={600}
        closable={false}
        className="dc-drawer-panel"
        forceRender={true}
        extra={
          <Space>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleOk}>Save</Button>
          </Space>
        }
      >
        <div className="">
          <Form
            form={frameDetailForm}
            initialValues={{ remember: false }}
            autoComplete="off"
            className="add-picker-form"
            layout="vertical"
            name="frameDetailForm"
            style={{
              backgroundColor: "#f3f3f3",
              borderRadius: "16px",
              marginRight: "24px",
              boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
              marginTop: "10px",
            }}
          >
            <Card>
              <div>
                <Form.Item
                  label="Assortment"
                  name="FrameArrangerID"
                  className="label-text region-field"
                  rules={[
                    {
                      required: true,
                      message: "Please select Assortment ID",
                    },
                  ]}
                >
                  {Object.keys(data).length ? (
                    <Select
                      allowClear
                      showSearch={true}
                      placeholder="Select Assortments"
                      optionFilterProp="children"
                      style={{ width: "100%" }}
                      filterOption={(input, option) => {
                        return option!.children?.[0] !== null
                          ? (option!.children?.[0] as unknown as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          : false;
                      }}
                      optionLabelProp="label"
                      maxTagCount="responsive"
                    >
                      {AssortmentDropdownElement()}
                    </Select>
                  ) : (
                    <Select
                      mode="multiple"
                      allowClear
                      showSearch={true}
                      placeholder="Select Assortments"
                      optionFilterProp="children"
                      style={{ width: "100%" }}
                      filterOption={(input, option) => {
                        return option!.children?.[0] !== null
                          ? (option!.children?.[0] as unknown as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          : false;
                      }}
                      optionLabelProp="label"
                      maxTagCount="responsive"
                    >
                      {AssortmentDropdownElement()}
                    </Select>
                  )}
                </Form.Item>
              </div>
            </Card>
            <Card>
              <div>
                <Form.Item
                  label="Collection Type"
                  name="CollectionType"
                  className="label-text region-field"
                  rules={[{ required: true, message: "Please select vendor" }]}
                >
                  <Select
                    showSearch={true}
                    placeholder="Select Collection Type"
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {collectionType?.map((item: any, index: any) => {
                      return (
                        <Option key={index} value={item?.Description}>
                          {item?.Description}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              {!Object.keys(data).length && (
                <Card className="mt-2">
                  <div>
                    <Button
                      className=""
                      onClick={() => {
                        setOfficeModal(true);
                        setOfficeList([]);
                      }}
                      disabled={officeSelectMode == "region"}
                    >
                      Quick Office Entry
                    </Button>
                    <div className="mt-2 d-flex justify-content-center">
                      <b>OR</b>
                    </div>
                    <Form.Item
                      label="Region"
                      name="Region"
                      className="label-text region-field"
                    >
                      <Select
                        showSearch={true}
                        allowClear={true}
                        mode="multiple"
                        placeholder="Select Region"
                        optionFilterProp="children"
                        onSearch={onSearch}
                        style={{ width: "100%" }}
                        onChange={(value) => handleAllData("region", value)}
                        filterOption={(input, option) =>
                          (option!.children as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        disabled={officeSelectMode == "QuickEntry"}
                        optionLabelProp="label"
                        maxTagCount="responsive"
                      >
                        {regionData?.map((item: any, index) => {
                          return (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="District"
                      name="District"
                      className="label-text"
                    >
                      <Select
                        showSearch={true}
                        allowClear={true}
                        mode="multiple"
                        placeholder="Select District"
                        optionFilterProp="children"
                        onSearch={onSearch}
                        style={{ width: "100%" }}
                        onChange={(value) => handleAllData("district", value)}
                        filterOption={(input, option) =>
                          (option!.children as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        optionLabelProp="label"
                        maxTagCount="responsive"
                        disabled={!districtData.length}
                      >
                        {districtData?.map((item: any, index) => {
                          return (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </Card>
              )}
              <div>
                <Form.Item
                  label="Offices"
                  name="Offices"
                  className="label-text region-field"
                  rules={[
                    !officeList.length
                      ? {
                          required: true,
                          message: "Please select atleast one office",
                        }
                      : { required: false, message: "" },
                  ]}
                >
                  <Select
                    showSearch={true}
                    allowClear={true}
                    mode="multiple"
                    disabled={!!Object.keys(data).length}
                    placeholder="Select Offices"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    filterOption={(input, option) => {
                      return option!.children?.[0] !== null
                        ? (option!.children as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        : false;
                    }}
                    optionLabelProp="label"
                    maxTagCount="responsive"
                    onChange={(value) => handleAllData("office", value)}
                  >
                    <Option value="All">Select All</Option>
                    {locationData?.map((item: any, index) => {
                      return (
                        <Option key={index} value={item.LocationNumber}>
                          {item.LocationNumber}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Start Date"
                  name="UpdatedDate"
                  className="label-text region-field"
                >
                  <DatePicker
                    format={DATE_FORMAT}
                    name="UpdatedDate"
                    value={updatedDate && moment(updatedDate)}
                    // disabledDate={(d) => !d || d.isAfter(moment())}
                    placeholder="Select Date"
                    onChange={(value: DatePickerProps["value"]) =>
                      setUpdatedDate(moment(value).format(DATE_FORMAT))
                    }
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Stop Date"
                  name="StopDate"
                  className="label-text region-field"
                >
                  <DatePicker
                    format={DATE_FORMAT}
                    name="StopDate"
                    value={stopDate && moment(stopDate)}
                    disabledDate={(d) => !d || d.isBefore(updatedDate)}
                    placeholder="Select Date"
                    onChange={(value: DatePickerProps["value"]) =>
                      setStopDate(moment(value).format(DATE_FORMAT))
                    }
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Changed Notes"
                  name="Notes"
                  className="label-text region-field"
                >
                  <Input name="Notes" />
                </Form.Item>
              </div>
            </Card>
          </Form>
        </div>
      </Drawer>
      <Modal
        title="Quick Office Entry"
        okText="Ok"
        centered
        width={500}
        confirmLoading={confirmLoading}
        style={{ top: 20 }}
        open={officeModal}
        onOk={handleOfficeList}
        onCancel={() => {
          setOfficeModal(false);
          setOfficeList([]);
        }}
      >
        <span>
          <TextArea
            onChange={(e) => setOfficeList(e.target.value)}
            value={officeList}
            rows={9}
          ></TextArea>
        </span>
      </Modal>
    </>
  );
}

export default AddOCMapping;
