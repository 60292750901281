import { Card, DatePicker, DatePickerProps, Form, Input } from "antd";
import moment from "moment";
import { DATE_FORMAT } from "src/util/columns";
import Button from "../common/Button/Button";
import { useCallback } from "react";
import { createFedExShipping } from "./sagas";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { fetchFedEx } from "./actions";

function FedExForm({
  form,
  shippingDate,
  setShippingDate,
  shippingStatus,
  shippingOpenDate,
  accounts,
  setConfirmLoading,
}: any) {
  const dispatch = useDispatch();

  const dateRequiredValidation =
    shippingDate === "Invalid date"
      ? { required: true, message: "Please select date" }
      : { required: false };
  
  const createFedEx = async (barcodeValue: any, date:any) => {
    setConfirmLoading(true);
    const payload = {
      officeNumber: barcodeValue,
      shippingDate: date.substring(0, 10),
      createdBy: accounts[0]?.username,
    };
    await createFedExShipping(payload)
      .then((res: any) => {
        showSuccessNotification(res);
        form.setFieldValue("OfficeBarcode", '');
        dispatch(fetchFedEx(date.substring(0, 10)));
        setConfirmLoading(false);
      })
      .catch((error: any) => {
        showErrorNotification(error?.response?.data?.error?.messages[0]);
        setConfirmLoading(false);
      });
  };
  
  const debounceLoadData =useCallback(debounce(createFedEx, 2000), []);
  
  const manageOnChange = (value: any, shippingDate: any) => {
    if (value?.length === 4)
      debounceLoadData(value, shippingDate);
  }

  return (
    <>
      <Card className="mb-4">
        {shippingOpenDate && (
          <div>
            <div className="d-flex justify-content-end">
              Current shipping date : {shippingOpenDate.substring(0, 10)}
            </div>
          </div>
        )}
        <Form
          form={form}
          autoComplete="off"
          className="add-form"
          layout="vertical"
        >
          <div>
            <Form.Item
              label="Shipping Date"
              name="shippingDate"
              rules={[dateRequiredValidation]}
            >
              <DatePicker
                format={DATE_FORMAT}
                name="shippingDate"
                defaultValue={shippingDate && moment(shippingDate)}
                value={shippingDate && moment(shippingDate)}
                disabledDate={(d) => !d}
                placeholder="Select Date"
                onChange={(value: DatePickerProps["value"]) =>
                  setShippingDate(moment(value).format(DATE_FORMAT))
                }
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Office Barcode"
              name="OfficeBarcode"
              className=""
              rules={[
                { required: true, message: "Please select Office Barcode" },
                () => ({
                  validator(rule, value) {
                    if (value.length != 4
                    ) {
                      return Promise.reject(
                        "Please scan or enter 4 digit office number"
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input
                disabled={!shippingStatus}
                onChange={(event) => manageOnChange(event?.target?.value, shippingDate)}
                maxLength={4}
              ></Input>
            </Form.Item>
          </div>
          <div>
          </div>
        </Form>
      </Card>
    </>
  );
}
export default FedExForm;
