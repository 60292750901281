import { combineReducers } from "redux";
import {
  pickerReducer,
  PICKER_REDUCER_KEY,
  REDUCER_KEY as VENDOR_REDUCER,
  vendorReducer,
} from "../components/vendor/vendor-reducer";
import {
  REDUCER_KEY as PRINT_TAGS_REDUCER,
  printTagsReducer,
} from "../components/printTags/printTags-reducer";
import {
  openPullReducer,
  REDUCER_KEY as OPEN_PULL_REDUCER,
} from "../components/openPulls/openPull-reducer";
import {
  framePickReducer,
  REDUCER_KEY as FRAME_PICK_REDUCER,
} from "../components/framePick/reducer";
import {
  replenishmentReducer,
  REDUCER_KEY as DC_REPLENISHMENT_REDUCER,
} from "../components/Replenishment/reducer";
import {
  REDUCER_KEY as VENDOR_ORDER_REDUCER,
  vendorOrderReducer,
  pendingOrderReducer,
  PENDING_ORDER_REDUCER_KEY,
} from "../components/vendorOrders/reducer";
import {
  REDUCER_KEY as ORDER_STATUS_REDUCER,
  DAILY_REPORT_REDUCER_KEY,
  VENDOR_EXLUSION_REDUCER_KEY,
  vendorExclusionReducer,
  orderStatusReducer,
  dailyReportReducer,
} from "../components/orderStatus/reducer";
import {
  REDUCER_KEY as ACCESSORY_REPLENISHMENT_REDUCER,
  accessoryReplenishmentReducer,
} from "../components/systemPreference/reducer";
import {
  REDUCER_KEY as KITS_MAINTENANCE_REDUCER,
  KIT_FRAME_REDUCER_KEY,
  kitFrameReducer,
  kitsMaintenanceReducer,
} from "src/components/kits/kitMaintenance/reducer";
import {
  ASSORTMENT_SKU_REDUCER_KEY,
  assortmentSkuReducer,
  REDUCER_KEY as FRAME_ARRANGER_QTY_REDUCER_KEY,
  frameArrangerQtyReducer,
} from "src/components/FrameArranger/FrameArrangerQty/reducer";
import {
  REDUCER_KEY as VENDOR_COLLECTION_MAPPING_REDUCER_KEY,
  vendorCollectionMappingReducer,
} from "src/components/FrameArranger/VendorsAndCollections/reducer";

import { REDUCER_KEY as PRODUCT_PLANOGRAM_REDUCER_KEY ,
  productPlanogramMappingReducer
} from "src/components/FrameArranger/ProductPlanogram/reducer";

import {
  REDUCER_KEY as OFFICE_COLLECTION_MAPPING_REDUCER_KEY,
  officeCollectionMappingReducer,
} from "src/components/FrameArranger/OfficeCollection/reducer";

import {
  REDUCER_KEY as VALIDATE_ACUITY_REDUCER,
  validateAcuityImportReducer
} from "src/components/FrameArranger/ValidateAcuityFrameImport/reducer";
import {REDUCER_KEY as KIT_PROCESSING_REDUCER_KEY, AVAILABLE_OFFICE_REDUCER_KEY, SELECTED_OFFICE_REDUCER_KEY, kitProcessingReducer, availableOfficeReducer, selectedOfficeReducer } from "src/components/kits/KitProcessing/reducer";
import {REDUCER_KEY as FEDEX_REDUCER_KEY, fedexReducer } from "src/components/FedEx/reducer";

const rootReducer = combineReducers({
  [VENDOR_REDUCER]: vendorReducer,
  [PICKER_REDUCER_KEY]: pickerReducer,
  [PRINT_TAGS_REDUCER]: printTagsReducer,
  [OPEN_PULL_REDUCER]: openPullReducer,
  [FRAME_PICK_REDUCER]: framePickReducer,
  [DC_REPLENISHMENT_REDUCER]: replenishmentReducer,
  [VENDOR_ORDER_REDUCER]: vendorOrderReducer,
  [ORDER_STATUS_REDUCER]: orderStatusReducer,
  [DAILY_REPORT_REDUCER_KEY]: dailyReportReducer,
  [PENDING_ORDER_REDUCER_KEY]: pendingOrderReducer,
  [VENDOR_EXLUSION_REDUCER_KEY]: vendorExclusionReducer,
  [ACCESSORY_REPLENISHMENT_REDUCER]: accessoryReplenishmentReducer,
  [KITS_MAINTENANCE_REDUCER]: kitsMaintenanceReducer,
  [KIT_FRAME_REDUCER_KEY]: kitFrameReducer,
  [FRAME_ARRANGER_QTY_REDUCER_KEY]: frameArrangerQtyReducer,
  [VENDOR_COLLECTION_MAPPING_REDUCER_KEY]: vendorCollectionMappingReducer,
  [PRODUCT_PLANOGRAM_REDUCER_KEY]: productPlanogramMappingReducer,
  [OFFICE_COLLECTION_MAPPING_REDUCER_KEY]: officeCollectionMappingReducer,
  [VALIDATE_ACUITY_REDUCER]: validateAcuityImportReducer,
  [KIT_PROCESSING_REDUCER_KEY]: kitProcessingReducer,
  [AVAILABLE_OFFICE_REDUCER_KEY]: availableOfficeReducer,
  [SELECTED_OFFICE_REDUCER_KEY]: selectedOfficeReducer,
  [FEDEX_REDUCER_KEY]: fedexReducer,
  [ASSORTMENT_SKU_REDUCER_KEY]: assortmentSkuReducer,
});

export default rootReducer;
