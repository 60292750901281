import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { ReactNode } from "react";
import ColorTag from "../components/common/ColorTag/ColorTag";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "antd";
import OfficeLocations from "src/components/common/OfficeLocations/OfficeLocations";
import { DragOutlined, FileOutlined, HolderOutlined } from "@ant-design/icons";
import { SortableHandle } from "react-sortable-hoc";
import Links from "src/components/common/Links/Links";

export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm A";
export const DATE_TIME_FORMAT_REPLENISHMENT = "YYYY-MM-DD hh:mm A";
export const DATE_FORMAT = "YYYY-MM-DD";

interface BASE_MODAL {
  id: string;
  key: React.Key;
  OfficeNum: string;
}
export interface PRINT_TAGS_DATA_TYPE extends BASE_MODAL {
  CountsByColor: any;
  ReceivedDateTime: string;
  ErrorTagCount: number;
  ProcessedDateTime: string;
  RemedyTicketNumber: string;
  RequestProcessedBy: string;
  ProcessedBy: string;
  IsActive: boolean;
}

export interface OPEN_PULL_DATA_TYPE extends BASE_MODAL {
  OfficeNumber: string;
  NickName: string;
  FullName: string;
  OrderDate: string;
  VendorId: string;
  FrameSource: string;
  Status: string;
  LabName: string;
  OfficeName: string;
  OrderNum: string;
  ColorRow: string;
  Vendor: string;
  Employee: string;
  Collection: string;
  ItemName: string;
  ItemStatus: string;
  LabStatus: string;
  LocationStatus: string;
  UPCCode: string;
}
export interface FRAME_PICK_DATA_TYPE extends BASE_MODAL {
  VendorName: any;
  FrameCollection: any;
  OrderNum: string;
  ItemID: string;
  OrderDate: string;
  FullName: string;
  VendorId: string;
  FrameSource: string;
  Status: string;
  LabName: string;
  OfficeName: string;
}
export interface REPLENISHMENT_DATA_TYPE extends BASE_MODAL {
  OrderQuantity: any;
  Id: any;
  SKU: any;
  FrameName: string;
  ItemGroup: string;
  Collection: string;
  VendorName: any;
  Location: string;
  MinQuantity: string;
  MaxQuantity: string;
  OrderID: string;
  OrderDate: string;
  PreviousOrderId: string;
  PreviousOrderStatus: string;
  PreviousOrderDate: string;
  ApprovalStatus: string;
  CreatedDate: string;
  ModifiedDate: string;
  ModifiedBy: string;
  OverrideOrderQuantity: any;
  UnitCost: any;
  ExtCost: any;
}

export interface VENDOR_ORDER_DATA_TYPE extends BASE_MODAL {
  ID: string;
  ItemID: string;
  ItemName: string;
  UPCCode: any;
  Quantity: string;
  Received: string;
  Cost: string;
  VendorReceiptNumber: any;
}

export interface PENDING_ORDER_DATA_TYPE extends BASE_MODAL {
  VendorName: any;
  ID: string;
  ItemID: string;
  ItemName: string;
  UPCCode: any;
  Quantity: string;
  Received: string;
  Cost: string;
  OrderDate: any;
}

export interface VENDOR_DATA_TYPE extends BASE_MODAL {
  ID: number;
  VendorName: string;
}
export interface OFFICE_DATA_TYPE extends BASE_MODAL {
  ID: number;
  LocationName: string;
}

export interface PICKER_DATA_TYPE extends BASE_MODAL {
  PickerId: string;
  PickerName: string;
}

export interface ORDER_STATUS_DATA_TYPE extends BASE_MODAL {
  OrderNumber: any;
  OrderDate: string;
  OrderStatus: string;
  OfficeNumber: string;
  VendorName: any;
  LaboratoryName: any;
  FrameCollection: string;
  FrameName: string;
  UpcCode: string;
  PickerFullName: string;
  ShippingStatus: string;
  FrameStatus: string;
  WorkflowStatus: string;
  VendorOrderStatus: string;
  LaboratoryStatus: string;
  ColorTag: string;
}

export interface DAILY_REPORT_DATA_TYPE {
  OrderDate: any;
  FrameSource: string;
  LabName: string;
  Region: string;
  District: any;
  OfficeManager: any;
  OfficeName: string;
}

export interface ACCESSORY_REPLENISHMENT_DATA_TYPE extends BASE_MODAL {
  ItemName: string;
  SKU: any;
  ReplenishmentType: string;
  OrderQuantity: any;
  TriggerQuantity: any;
  StockedByDC: any;
  LocationID: any;
  SalesAfterLastReplenishDate: any;
  CurrentOnHandQuantity: any;
  LastReplenishmentDate: any;
  InTransitQuantity: any;
}

export interface KITS_MAINTENANCE_DATA_TYPE extends BASE_MODAL {
  KitId: any;
  KitName: string;
  Vendor: any;
  Collection: any;
  ProductOrder: string;
  ModifiedDate: any;
  StatusText: any;
  PlannedShipDate: any;
}
export interface KITS_FRAME_DATA_TYPE extends BASE_MODAL {
  Collection: string;
  Model: any;
  Color: string;
  ColorDescription: any;
  EyeSize: any;
  UPC: any;
  FrameType: string;
  MEDCost: any;
}

export interface KITS_PROCESSING_DATA_TYPE extends BASE_MODAL {
  KitId: any;
  KitName: string;
  Vendor: any;
  Collection: any;
  PODate: string;
  StatusText: any;
  OfficeNumbers: any;
  ProcessedDate: any;
}

export interface OFFICE_COLLECTION_LISTING_DATA_TYPE extends BASE_MODAL {
  OfficeNum: any;
  LocationName: any;
  Vendor: any;
  Collection: any;
  CollectionType: any;
  Removed: any;
  SellThrough: any;
  UpdatedDate: any;
  FrameArrangerID: any;
  UpdateNotes: any;
  ArrangerQty: any;
  District: any;
  Region: any;
  StopDate: any;
  AssortmentName: any;
}
export interface COLLECTION_LISTING_DATA_TYPE extends BASE_MODAL {
  AssortmentId: any;
  AssortmentName: any;
  AcuityCollectionID: any;
  DatabaseCollection: any;
  AcuityVendorID: any;
  Vendor: any;
  Collection: any;
  FrameTypeID: any;
  FrameTypeDescription: any;
  ArrangerQty: any;
  AssortmentCount: any;
  CountperCollection: any;
  SunQty: any;
  Active: any;
  DateAdded: any;
  SKU: any;
  StartDate: any;
  StopDate: any;
}
export interface VENDOR_COLLECTION_LISTING_DATA_TYPE extends BASE_MODAL {
  DCReplen: any;
  AcuityCollectionID: any;
  DatabaseCollectionID: any;
  AcuityVendorID: any;
  Vendor: any;
  Collection: any;
  Notes: any;
}

export interface PRODUCT_PLANOGRAM_LISTING_DATA_TYPE extends BASE_MODAL {
  ID1: any;
  FrameArrangerID: any;
  Collection: any;
  Vendor: any;
  Model: any;
  StyleName: any;
  ColorNumber: any;
  ColorDescription: any;
  EyeSize: any;
  Bridge: any;
  Temple: any;
  A: any;
  B: any;
  ED: any;
  Material: any;
  Edge: any;
  UPC: any;
  SKUQty: any;
  ty: any;
  FrameType: any;
  Gender: any;
  FrameName: any;
  MEDCost: any;
  Wholesale: any;
  Retail: any;
  RecallDate: any;
  ItemGroup: any;
  NoReplenishmentDate: any;
  EffectiveDate: any;
  PhasingInStyle: any;
  Min: any;
  Max: any;
  StockedAtDC: any;
  LiveCost: any;
  LiveWholeSale: any;
  LiveRetail: any;
  FrameColorFamily: any;
  FrameShape: any;
  AgeGroup: any;
  Rank: any;
  ReceiptDate: any;
  ItemType: any;
  LifeCycleStatus: any;
  LifeCycleStatusDate: any;
  SKUGrade: any;
}

export const PRINT_TAGS_COLUMN: ColumnsType<PRINT_TAGS_DATA_TYPE> = [
  {
    title: "Office #",
    dataIndex: "OfficeNum",
    filterMultiple: true,
    sorter: (a, b) => a.OfficeNum?.localeCompare(b.OfficeNum),
    sortDirections: ["ascend", "descend"],
    fixed: "left",
    width: 150,
  },
  {
    title: "Remedy Ticket #",
    dataIndex: "RemedyTicketNumber",
    filterMultiple: true,
    sorter: (a, b) => a.RemedyTicketNumber?.localeCompare(b.RemedyTicketNumber),
    sortDirections: ["ascend", "descend"],
    fixed: "left",
    width: 150,
  },
  {
    title: "Received Date",
    dataIndex: "ReceivedDateTime",
    render: (text: string) => {
      return text ? moment(text).format(DATE_TIME_FORMAT) : "N/A";
    },
    width: 150,
    filterMultiple: true,
    sorter: (a, b) =>
      moment(a.ReceivedDateTime || 0).unix() -
      moment(b.ReceivedDateTime || 0).unix(),
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "Processed Date",
    dataIndex: "ProcessedDateTime",
    filterMultiple: true,
    sorter: (a, b) =>
      moment(a.ProcessedDateTime || 0).unix() -
      moment(b.ProcessedDateTime || 0).unix(),
    sortDirections: ["ascend", "descend"],
    render: (text: string) => {
      return text ? moment(text).format(DATE_TIME_FORMAT) : "N/A";
    },
    width: 150,
  },
  {
    title: "Processed By",
    dataIndex: "ProcessedBy",
    filterMultiple: true,
    sorter: (a, b) => a.ProcessedBy?.localeCompare(b.ProcessedBy),
    sortDirections: ["ascend", "descend"],
    width: 150,
  },
  // ,
  // {
  //   title: "Total Count",
  //   dataIndex: "ErrorTagCount",
  // }
];

export const OPEN_PULL_COLUMN: ColumnsType<OPEN_PULL_DATA_TYPE> = [
  {
    title: "Office #",
    dataIndex: "OfficeNum",
    filterMultiple: true,
    sorter: (a, b) => a.OfficeNum?.localeCompare(b.OfficeNum),
    sortDirections: ["ascend", "descend"],
    fixed: "left",
    width: 150,
  },
  {
    title: "Employee",
    dataIndex: "Employee",
    sorter: (a, b) => a.Employee?.localeCompare(b.Employee),
    sortDirections: ["ascend", "descend"],
    filterMultiple: true,
    width: 150,
  },
  {
    title: "Collection",
    dataIndex: "Collection",
    sorter: (a, b) => a.Collection?.localeCompare(b.Collection),
    sortDirections: ["ascend", "descend"],
    filterMultiple: true,
    width: 150,
  },
  {
    title: "Item Name",
    dataIndex: "ItemName",
    sorter: (a, b) => a.ItemName?.localeCompare(b.ItemName),
    sortDirections: ["ascend", "descend"],
    filterMultiple: true,
    width: 130,
  },
  {
    title: "UPC Code",
    dataIndex: "UPCCode",
    filterMultiple: true,
    sorter: (a, b) => a.UPCCode?.localeCompare(b.UPCCode),
    sortDirections: ["ascend", "descend"],
    width: 150,
  },

  {
    title: "Order Date",
    dataIndex: "OrderDate",
    render: (text: string) => {
      return text ? moment(text).format(DATE_FORMAT) : "N/A";
    },
    filterMultiple: true,
    sorter: (a, b) =>
      moment(a.OrderDate || 0).unix() - moment(b.OrderDate || 0).unix(),
    sortDirections: ["ascend", "descend"],
    width: 150,
  },
  {
    title: "Status",
    dataIndex: "Status",
    sorter: (a, b) => a.Status?.localeCompare(b.Status),
    sortDirections: ["ascend", "descend"],
    filterMultiple: true,
    width: 150,
  },
  {
    title: "Notes",
    dataIndex: "Notes",
    width: 150,
  },
  {
    title: "Office Name",
    dataIndex: "OfficeName",
    sorter: (a, b) => a.OfficeName?.localeCompare(b.OfficeName),
    sortDirections: ["ascend", "descend"],
    filterMultiple: true,
    width: 150,
  },
  {
    title: "Order Number",
    dataIndex: "OrderNum",
    sorter: (a, b) => a.OrderNum?.localeCompare(b.OrderNum),
    sortDirections: ["ascend", "descend"],
    filterMultiple: true,
    width: 130,
  },
];

export const FRAME_PICK_COLUMN: ColumnsType<FRAME_PICK_DATA_TYPE> = [
  {
    title: "Office #",
    dataIndex: "OfficeNum",
    filterMultiple: true,
    fixed: "left",
  },
  {
    title: "Order Number",
    dataIndex: "OrderNum",
    filterMultiple: true,
  },
  // {
  //   title: "Status",
  //   dataIndex: "Status",
  // },
  {
    title: "Vendor Name",
    dataIndex: "VendorName",
    sorter: (a, b) => a.VendorName?.localeCompare(b.VendorName),
    sortDirections: ["ascend"],
  },
  {
    title: "Frame Name",
    dataIndex: "FrameName",
    filterMultiple: true,
    width: 250,
  },
  {
    title: "UPC Code",
    dataIndex: "UPCCode",
  },
  {
    title: "Item Status",
    dataIndex: "ItemStatus",
  },
  {
    title: "Location Status",
    dataIndex: "LocationStatus",
  },
  {
    title: "Label Printed",
    dataIndex: "LabelPrinted",
    render: (LabelPrinted: string): ReactNode => {
      const status = LabelPrinted ? "Yes" : "No";
      return <ColorTag type={status.toLowerCase()}>{status}</ColorTag>;
    },
  },
];

export const DC_REPLENISHMENT_COLUMN: ColumnsType<REPLENISHMENT_DATA_TYPE> = [
  // {
  //   title: "Office #",
  //   dataIndex: "Location",
  //   fixed:"left",
  //   width:120
  // },
  {
    title: "SKU",
    dataIndex: "SKU",
    filterMultiple: true,
    sorter: (a, b) => a.SKU - b.SKU,
    sortDirections: ["ascend", "descend"],
    width: 120,
  },
  {
    title: "Frame Name",
    dataIndex: "FrameName",
    filterMultiple: true,
    width: 300,
  },
  {
    title: "Order Qty",
    dataIndex: "OrderQuantity",
    align: "center",
    sorter: (a, b) => a.OrderQuantity - b.OrderQuantity,
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: "Override Order Qty",
    dataIndex: "OverrideOrderQuantity",
    align: "center",
    width: 150,
    filtered: true,
  },
  {
    title: "MED Cost",
    dataIndex: "UnitCost",
    align: "center",
    sorter: (a, b) => a.UnitCost - b.UnitCost,
    sortDirections: ["ascend", "descend"],
    width: 150,
    filtered: true,
    render: (text: any) => {
      return text ? "$" + (Math.round(text * 100) / 100).toFixed(2) : "$0";
    },
  },
  {
    title: "Ext Cost",
    dataIndex: "ExtCost",
    align: "center",
    sorter: (a, b) => a.ExtCost - b.ExtCost,
    sortDirections: ["ascend", "descend"],
    width: 150,
    filtered: true,
    render: (text: any) => {
      return text ? "$" + (Math.round(text * 100) / 100).toFixed(2) : "$0";
    },
  },
  {
    title: "OnHand Qty",
    dataIndex: "OnHandQuantity",
    width: 110,
    align: "center",
  },
  {
    title: "Back Order Qty",
    dataIndex: "BackOrderQuantity",
    width: 100,
    align: "center",
  },
  {
    title: "Min Qty",
    dataIndex: "MinQuantity",
    align: "center",
    width: 100,
  },
  {
    title: "Max Qty",
    dataIndex: "MaxQuantity",
    align: "center",
    width: 100,
  },
  // {
  //   title: "Order ID",
  //   dataIndex: "OrderID",
  //   align:"center",
  //   width:100
  // },
  {
    title: "Order Date",
    dataIndex: "OrderDate",
    sorter: (a, b) =>
      moment(a.OrderDate || 0).unix() - moment(b.OrderDate || 0).unix(),
    sortDirections: ["ascend", "descend"],
    render: (text: string) => {
      return text ? moment(text).format(DATE_FORMAT) : "N/A";
    },
    width: 120,
  },
  {
    title: "Prev Order ID",
    dataIndex: "PreviousOrderId",
    align: "center",
    width: 100,
  },
  // {
  //   title: "Prev Order Status",
  //   dataIndex: "PreviousOrderStatus",
  //   // render: (LabelPrinted: string): ReactNode => {
  //   //   const status = LabelPrinted ? "Yes" : "No"
  //   //   return <ColorTag type={status.toLowerCase()}>{status}</ColorTag>
  //   // }
  //   width:100
  // },
  {
    title: "Prev Order Date",
    dataIndex: "PreviousOrderDate",
    sorter: (a, b) =>
      moment(a.PreviousOrderDate || 0).unix() -
      moment(b.PreviousOrderDate || 0).unix(),
    sortDirections: ["ascend", "descend"],
    render: (text: string) => {
      return text ? moment(text).format(DATE_FORMAT) : "N/A";
    },
    align: "center",
    width: 130,
  },
  {
    title: "Approval Status",
    dataIndex: "ApprovalStatus",
    align: "center",
    width: 110,
  },
  {
    title: "Created Date",
    dataIndex: "CreatedDate",
    sorter: (a, b) =>
      moment(a.CreatedDate || 0).unix() - moment(b.CreatedDate || 0).unix(),
    sortDirections: ["ascend", "descend"],
    render: (text: string) => {
      return text ? moment(text).format(DATE_FORMAT) : "N/A";
    },
    width: 120,
  },
  {
    title: "Modified Date",
    dataIndex: "ModifiedDate",
    sorter: (a, b) =>
      moment(a.ModifiedDate || 0).unix() - moment(b.ModifiedDate || 0).unix(),
    sortDirections: ["ascend", "descend"],
    render: (text: string) => {
      return text ? moment(text).format(DATE_FORMAT) : "N/A";
    },
    width: 120,
  },
  {
    title: "Modified By",
    dataIndex: "ModifiedBy",
    width: 100,
  },
];

export const PICKER_COLUMN: ColumnsType<PICKER_DATA_TYPE> = [
  {
    title: "Picker Id",
    dataIndex: "PickerId",
    filterMultiple: true,
  },
  {
    title: "Picker Name",
    dataIndex: "PickerName",
    filterMultiple: true,
  },
];

export const VENDOR_COLUMN: ColumnsType<VENDOR_DATA_TYPE> = [
  {
    title: "Vendor Id",
    dataIndex: "ID",
    filterMultiple: true,
  },
  {
    title: "Vendor Name",
    dataIndex: "VendorName",
    filterMultiple: true,
  },
];
export const VENDOR_AVIALBLE_COLUMN: ColumnsType<VENDOR_DATA_TYPE> = [
  {
    title: "Available Vendors",
    dataIndex: "VendorName",
    filterMultiple: true,
  },
];
export const VENDOR_EXCLUSION_COLUMN: ColumnsType<VENDOR_DATA_TYPE> = [
  {
    title: "Excluded Vendors",
    dataIndex: "VendorName",
    filterMultiple: true,
  },
];

export const VENDOR_ORDER_COLUMN: ColumnsType<VENDOR_ORDER_DATA_TYPE> = [
  {
    title: "Item Name",
    dataIndex: "ItemName",
    filterMultiple: true,
    sorter: (a, b) => a.ItemName?.localeCompare(b.ItemName),
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "UPCCode",
    dataIndex: "UPCCode",
    filterMultiple: true,
    sorter: (a, b) => a.UPCCode - b.UPCCode,
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "Ordered Qty",
    dataIndex: "Quantity",
    align: "center",
  },
  {
    title: "Prev Received Qty",
    dataIndex: "PreviousReceived",
    align: "center",
  },
  {
    title: "Prev Item Price",
    dataIndex: "PreviousCost",
    render: (text: string) => {
      return "$ " + text;
    },
    align: "center",
  },
  {
    title: "Received Qty",
    dataIndex: "Received",
    filtered: true,
  },
  {
    title: "Item Price",
    dataIndex: "Cost",
    filtered: true,
  },
];

export const ORDER_STATUS_COLUMN: ColumnsType<ORDER_STATUS_DATA_TYPE> = [
  {
    title: "#Office",
    dataIndex: "OfficeNumber",
    fixed: "left",
    width: 120,
  },
  {
    title: "Order Number",
    dataIndex: "OrderNumber",
    filterMultiple: true,
    sorter: (a, b) => a.OrderNumber - b.OrderNumber,
    width: 150,
  },
  {
    title: "Order Date",
    dataIndex: "OrderDate",
    sorter: (a, b) =>
      moment(a.OrderDate || 0).unix() - moment(b.OrderDate || 0).unix(),
    sortDirections: ["ascend", "descend"],
    render: (text: string) => {
      return text ? moment(text).format(DATE_FORMAT) : "N/A";
    },
    width: 100,
  },
  {
    title: "Order Status",
    dataIndex: "OrderStatus",
    width: 250,
  },
  // {
  //   title: "Color Tag",
  //   dataIndex: "ColorTag",
  //   render: (colorTag: string): ReactNode => {
  //     const status = colorTag ? "Yes" : "No"
  //       return <ColorTag type={status.toLowerCase()}>{status}</ColorTag>
  //   },
  //   width:150
  // },
  {
    title: "Frame Collection",
    dataIndex: "FrameCollection",
    width: 150,
  },
  {
    title: "Laboratory Name",
    dataIndex: "LaboratoryName",
    width: 150,
  },

  {
    title: "Vendor Name",
    dataIndex: "VendorName",
    sorter: (a, b) => a.VendorName?.localeCompare(b.VendorName),
    filterMultiple: true,
    width: 150,
  },
  {
    title: "Frame Name",
    dataIndex: "FrameName",
    width: 250,
  },
  {
    title: "UPC Code",
    dataIndex: "UpcCode",
    width: 150,
  },
  {
    title: "Processed By",
    dataIndex: "PickerFullName",
    width: 120,
  },
  {
    title: "Shipping Status",
    dataIndex: "ShippingStatus",
    width: 120,
  },
  {
    title: "Frame Status",
    dataIndex: "FrameStatus",
    width: 150,
  },
  {
    title: "Workflow Status",
    dataIndex: "WorkflowStatus",
    width: 150,
  },
  {
    title: "Vendor Order Status",
    dataIndex: "VendorOrderStatus",
    width: 150,
  },
  {
    title: "Laboratory Status",
    dataIndex: "LaboratoryStatus",
    sorter: (a, b) => a.LaboratoryStatus?.localeCompare(b.LaboratoryStatus),
    filterMultiple: true,
    width: 150,
  },
];

export const DAILY_REPORT_COLUMNS = [
  {
    title: "Order Date",
    dataIndex: "OrderDate",
    sorter: (a: { OrderDate: any }, b: { OrderDate: any }) =>
      moment(a.OrderDate || 0).unix() - moment(b.OrderDate || 0).unix(),
    sortDirections: ["ascend", "descend"],
    render: (text: string) => {
      return text ? moment(text).format(DATE_FORMAT) : "N/A";
    },
    width: 100,
  },
  {
    title: "Frame Source",
    dataIndex: "FrameSource",
    key: "FrameSource",
    width: 100,
  },
  {
    title: "Lab Name",
    dataIndex: "LabName",
    key: "LabName",
    width: 100,
  },
  // {
  //   title: "Region",
  //   dataIndex: "Region",
  //   key: "Region",
  //   width: 100
  // },
  // {
  //   title: "District",
  //   dataIndex: "District",
  //   key: "District",
  //   width: 100
  // },
  {
    title: "District Manager",
    dataIndex: "DistrictManager",
    key: "DistrictManager",
    width: 150,
  },
  // {
  //   title: "Office Name",
  //   dataIndex: "OfficeName",
  //   key: "OfficeName",
  //   width: 150
  // },
  {
    title: "Order Number",
    dataIndex: "OrderNumber",
    filterMultiple: true,
    sorter: (a: { OrderNumber: number }, b: { OrderNumber: number }) =>
      a.OrderNumber - b.OrderNumber,
    width: 150,
  },
  // {
  //   title: "Vendor Name",
  //   dataIndex: "VendorName",
  //   sorter: (a: { VendorName: string; }, b: { VendorName: any; }) => a.VendorName?.localeCompare(b.VendorName),
  //   //filterMultiple: true,
  //   width: 150
  // },
  // {
  //   title: "Collection",
  //   dataIndex: "FrameCollection",
  //   width: 150
  // },
  // {
  //   title: "Item Name",
  //   dataIndex: "ItemName",
  //   width: 250
  // },
  // {
  //   title: "UPC Code",
  //   dataIndex: "UPCCode",
  //   width: 150
  // },
  {
    title: "Lab Status",
    dataIndex: "LabStatus",
    sorter: (a: { LabStatus: string }, b: { LabStatus: any }) =>
      a.LabStatus?.localeCompare(b.LabStatus),
    //filterMultiple: true,
    width: 150,
  },
  {
    title: "Location Status",
    dataIndex: "LocationStatus",
    width: 120,
  },
  {
    title: "Notes",
    dataIndex: "Notes",
    width: 200,
  },
  {
    title: "Item Status",
    dataIndex: "ItemStatus",
    key: "ItemStatus",
    width: 150,
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    width: 150,
  },
  {
    title: "Ref#",
    dataIndex: "ReferenceNumber",
    key: "Ref",
    width: 150,
  },
];

export const DAILY_REPORT_EXCEL_COLUMNS = [
  {
    header: "Order Date",
    key: "OrderDate",
    width: 20,
  },
  {
    header: "Frame Source",
    key: "FrameSource",
    width: 20,
  },
  {
    header: "Lab Name",
    key: "LabName",
    width: 20,
  },
  {
    header: "Region",
    key: "Region",
    width: 20,
  },
  {
    header: "District",
    key: "District",
    width: 20,
  },

  {
    header: "Office Name",
    key: "OfficeName",
    width: 20,
  },
  {
    header: "Order Number",
    key: "OrderNumber",
    width: 20,
    align: "center",
  },
  {
    header: "Vendor Name",
    key: "VendorName",
    width: 20,
  },
  {
    header: "Item Name",
    key: "ItemName",
    width: 40,
  },
  {
    header: "UPC Code",
    key: "UPCCode",
    width: 20,
  },
  {
    header: "Lab Status",
    key: "LabStatus",
    width: 20,
  },
  {
    header: "Location Status",
    key: "LocationStatus",
    width: 20,
  },
  {
    header: "Notes",
    key: "Notes",
    width: 20,
  },
  {
    header: "Item Status",
    key: "ItemStatus",
    width: 20,
  },
  {
    header: "Status",
    key: "Status",
    width: 20,
  },
  {
    header: "Ref#",
    key: "ReferenceNumber",
    width: 20,
  },
];

export const DC_REPLENISHMENT_EXCEL_COLUMN = [
  {
    header: "SKU",
    key: "SKU",
    width: 20,
  },
  {
    header: "Frame Name",
    key: "FrameName",
    width: 20,
  },
  {
    header: "Vendor",
    key: "VendorName",
    width: 20,
  },
  {
    header: "Collection",
    key: "Collection",
    width: 20,
  },
  {
    header: "Order ID",
    key: "OrderID",
    width: 20,
  },
  {
    header: "Order Quantity",
    key: "OrderQuantity",
    width: 20,
  },
  {
    header: "Override Quantity",
    key: "OverrideOrderQuantity",
    width: 20,
  },
  {
    header: "MED Cost",
    key: "UnitCost",
    width: 20,
  },
  {
    header: "Ext Cost",
    key: "ExtCost",
    width: 20,
  },
  {
    header: "OnHand Quantity",
    key: "OnHandQuantity",
    width: 20,
  },
  {
    header: "Back Order Quantity",
    key: "BackOrderQuantity",
    width: 20,
  },
  {
    header: "Min Quantity",
    key: "MinQuantity",
    width: 20,
  },
  {
    header: "Max Quantity",
    key: "MaxQuantity",
    width: 20,
  },
  {
    header: "Order Date",
    key: "OrderDate",
    width: 20,
  },
  {
    header: "Previous Order Id",
    key: "PreviousOrderId",
    width: 20,
  },
  {
    header: "Prev Order Date",
    key: "PreviousOrderDate",
    width: 20,
  },
  {
    header: "Approval Status",
    key: "ApprovalStatus",
    width: 20,
  },
  {
    header: "Created Date",
    key: "CreatedDate",
    width: 20,
  },
  {
    header: "Modified Date",
    key: "ModifiedDate",
    width: 20,
  },
  {
    header: "Modified By",
    key: "ModifiedBy",
    width: 20,
  },
];

export const ORDER_STATUS_VALUES = [
  {
    name: "Shipped",
  },
  {
    name: "Ordered 3-5 business days",
  },
  {
    name: "Ordered 7-10 business days",
  },
  {
    name: "Coming directly from the vendor - expect delays",
  },
  {
    name: "BackOrdered",
  },
  {
    name: "Ordered - waiting on confirmation from vendor",
  },
  {
    name: "Discontinued",
  },
];

export const PENDING_ORDER_COLUMN: ColumnsType<PENDING_ORDER_DATA_TYPE> = [
  {
    title: "Order Number",
    dataIndex: "OrderNumber",
    width: 100,
  },
  {
    title: "Order Date",
    dataIndex: "OrderDate",
    sorter: (a, b) =>
      moment(a.OrderDate || 0).unix() - moment(b.OrderDate || 0).unix(),
    sortDirections: ["ascend", "descend"],
    render: (text: string) => {
      return text ? moment(text).format(DATE_FORMAT) : "N/A";
    },
    width: 100,
  },
  {
    title: "Item Description",
    dataIndex: "ItemName",
    width: 250,
  },
  {
    title: "UPCCode",
    dataIndex: "UPCCode",
    filterMultiple: true,
    sorter: (a, b) => a.UPCCode - b.UPCCode,
    sortDirections: ["ascend", "descend"],
    width: 120,
  },
  {
    title: "Vendor Name",
    dataIndex: "VendorName",
    sorter: (a, b) => a.VendorName?.localeCompare(b.VendorName),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: "Ordered Qty",
    dataIndex: "Quantity",
    align: "center",
    width: 100,
  },
  {
    title: "Backorder Qty",
    dataIndex: "BackOrderQuantity",
    align: "center",
    width: 100,
  },
  {
    title: "Prev Received Qty",
    dataIndex: "PreviousReceived",
    align: "center",
    width: 120,
  },
  {
    title: "Prev Item Price",
    dataIndex: "PreviousCost",
    render: (text: string) => {
      return "$ " + text;
    },
    align: "center",
    width: 100,
  },
  // {
  //   title: 'Received Qty',
  //   dataIndex: 'Received',
  //   filtered: true,
  // },
  // {
  //   title: 'Item Price',
  //   dataIndex: 'Cost',
  //   filtered: true,
  // }
];

export const ACCESSORY_REPLENISHMENT_COLUMN: ColumnsType<ACCESSORY_REPLENISHMENT_DATA_TYPE> =
  [
    {
      title: "Product",
      dataIndex: "ItemName",
      sorter: (a, b) => a.ItemName?.localeCompare(b.ItemName),
      sortDirections: ["ascend", "descend"],
      width: 100,
    },
    {
      title: "UPCCode",
      dataIndex: "SKU",
      filterMultiple: true,
      sorter: (a, b) => a.SKU - b.SKU,
      sortDirections: ["ascend", "descend"],
      width: 120,
    },
    {
      title: "Replenishment Type",
      dataIndex: "ReplenishmentType",
      render: (text: string) => {
        return text;
      },
      sorter: (a, b) => a.ReplenishmentType?.localeCompare(b.ReplenishmentType),
      sortDirections: ["ascend", "descend"],
      width: 130,
    },
    {
      title: "Trigger Quantity",
      dataIndex: "ReplenishThreshold",
      filterMultiple: true,
      width: 100,
    },
    {
      title: "Order Quantiy",
      dataIndex: "OrderQuantity",
      filterMultiple: true,
      width: 100,
    },
    {
      title: "On Hand Quantity",
      dataIndex: "CurrentOnHandQuantity",
      filterMultiple: true,
      sorter: (a, b) => a.CurrentOnHandQuantity - b.CurrentOnHandQuantity,
      sortDirections: ["ascend", "descend"],
      width: 100,
    },
    {
      title: "In Transit",
      dataIndex: "InTransitQuantity",
      filterMultiple: true,
      sorter: (a, b) => a.InTransitQuantity - b.InTransitQuantity,
      sortDirections: ["ascend", "descend"],
      width: 100,
    },
    {
      title: "Stocked By DC",
      dataIndex: "IsStockedAtDC",
      render: (StockedByDC: boolean): ReactNode => {
        const status = StockedByDC === true ? "Yes" : "No";
        return <ColorTag type={status.toLowerCase()}>{status}</ColorTag>;
      },
      width: 100,
    },
    {
      title: "Office",
      dataIndex: "LocationID",
      filterMultiple: true,
      sorter: (a, b) => a.LocationID?.localeCompare(b.LocationID, "en"),
      sortDirections: ["ascend", "descend"],
      width: 100,
    },
    {
      title: "Consider Backorder",
      dataIndex: "IsConsiderBackorder",
      render: (IsConsiderBackorder: boolean): ReactNode => {
        const status = IsConsiderBackorder === true ? "Yes" : "No";
        return <ColorTag type={status.toLowerCase()}>{status}</ColorTag>;
      },
      width: 100,
    },
    {
      title: "Current Sales Volume",
      dataIndex: "SalesAfterLastReplenishDate",
      sorter: (a, b) =>
        a.SalesAfterLastReplenishDate - b.SalesAfterLastReplenishDate,
      sortDirections: ["ascend", "descend"],
      align: "center",
      width: 120,
    },
    {
      title: "Last Repleniment Date",
      dataIndex: "LastReplenishmentDate",
      sorter: (a, b) =>
        moment(a.LastReplenishmentDate || 0).unix() -
        moment(b.LastReplenishmentDate || 0).unix(),
      sortDirections: ["ascend", "descend"],
      render: (text: string) => {
        return text ? moment(text).format(DATE_TIME_FORMAT) : "N/A";
      },
      align: "center",
      width: 150,
    },
  ];

export const KITS_MAINTENANCE_COLUMN: ColumnsType<KITS_MAINTENANCE_DATA_TYPE> =
  [
    {
      title: "Kit ID",
      dataIndex: "KitId",
      filterMultiple: true,
      sorter: (a, b) => a.KitId - b.KitId,
      sortDirections: ["ascend", "descend"],
      width: 100,
      align: "center",
    },
    {
      title: "Kit Name",
      dataIndex: "KitName",
      filterMultiple: true,
      sorter: (a, b) =>
        a.KitName?.localeCompare(b.KitName, "en", { numeric: true }),
      sortDirections: ["ascend", "descend"],
      width: 100,
      align: "center",
    },
    {
      title: "Collection Name",
      dataIndex: "Collection",
      width: 100,
      align: "center",
      render: (text: string): ReactNode => {
        return (
          <OfficeLocations locationName={text} modalTitle={"Collections"} />
        );
      },
    },
    {
      title: "Date",
      dataIndex: "ModifiedDate",
      filterMultiple: true,
      sorter: (a, b) =>
        moment(a.ModifiedDate || 0).unix() - moment(b.ModifiedDate || 0).unix(),
      sortDirections: ["ascend", "descend"],
      render: (text: string) => {
        return text ? moment(text).format(DATE_TIME_FORMAT) : "N/A";
      },
      width: 100,
      align: "center",
    },
    {
      title: "Planned Ship Date",
      dataIndex: "PlannedShipDate",
      filterMultiple: true,
      sorter: (a, b) =>
        moment(a.PlannedShipDate || 0).unix() -
        moment(b.PlannedShipDate || 0).unix(),
      sortDirections: ["ascend", "descend"],
      render: (text: string) => {
        return text ? moment(text).format(DATE_TIME_FORMAT) : "N/A";
      },
      width: 100,
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "StatusText",
      sorter: (a: { StatusText: any }, b: { StatusText: any }) =>
        a.StatusText?.localeCompare(b.StatusText),
      sortDirections: ["ascend", "descend"],
      render: (StatusText: string): ReactNode => {
        const status =
          StatusText === "Active"
            ? "Active"
            : StatusText === "Archived"
            ? "Archived"
            : "Inactive";
        return <ColorTag type={status.toLowerCase()}>{StatusText}</ColorTag>;
      },
      width: 100,
      align: "center",
    },
  ];

export const KITS_PROCESSING_COLUMN: ColumnsType<KITS_PROCESSING_DATA_TYPE> = [
  {
    title: "Kit Name",
    dataIndex: "KitName",
    filterMultiple: true,
    sorter: (a, b) => a.KitName?.localeCompare(b.KitName),
    sortDirections: ["ascend", "descend"],
    width: 100,
    align: "center",
  },
  {
    title: "PO Date",
    dataIndex: "PODate",
    filterMultiple: true,
    sorter: (a, b) =>
      moment(a.PODate || 0).unix() - moment(b.PODate || 0).unix(),
    sortDirections: ["ascend", "descend"],
    render: (text: string) => {
      return text ? moment(text).format(DATE_TIME_FORMAT) : "N/A";
    },
    width: 100,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "StatusText",
    sorter: (a: { StatusText: any }, b: { StatusText: any }) =>
      a.StatusText?.localeCompare(b.StatusText),
    sortDirections: ["ascend", "descend"],
    render: (StatusText: string): ReactNode => {
      const status =
        StatusText === "Active"
          ? "Active"
          : StatusText === "Archived"
          ? "Archived"
          : "Inactive";
      return <ColorTag type={status.toLowerCase()}>{StatusText}</ColorTag>;
    },
    width: 100,
    align: "center",
  },
  {
    title: "Office",
    dataIndex: "OfficeNumbers",
    //filterMultiple: true,
    // sorter: (a, b) => a.OfficeNumbers?.localeCompare(b.OfficeNumbers),
    // sortDirections: ["ascend", "descend"],
    width: 100,
    align: "center",
    render: (OfficeNumbers: any): ReactNode => {
      return (
        <OfficeLocations
          locationName={OfficeNumbers}
          modalTitle="Office Locations"
        />
      );
    },
  },
  {
    title: "Processed Date",
    dataIndex: "ProcessedDate",
    filterMultiple: true,
    sorter: (a, b) =>
      moment(a.ProcessedDate || 0).unix() - moment(b.ProcessedDate || 0).unix(),
    sortDirections: ["ascend", "descend"],
    render: (text: string) => {
      return text ? moment(text).format(DATE_TIME_FORMAT) : "N/A";
    },
    width: 100,
    align: "center",
  },
];

export const KITS_FRAME_COLUMN: ColumnsType<KITS_FRAME_DATA_TYPE> = [
  {
    title: "Color Description",
    dataIndex: "ColorDescription",
    filterMultiple: true,
    sorter: (a, b) => a.ColorDescription?.localeCompare(b.ColorDescription),
    sortDirections: ["ascend", "descend"],
    width: 150,
  },
  {
    title: "Eye Size",
    dataIndex: "EyeSize",
    filterMultiple: true,
    sorter: (a, b) => a.EyeSize - b.EyeSize,
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: "UPCCode",
    dataIndex: "UPC",
    filterMultiple: true,
    width: 120,
  },
  {
    title: "MED Cost",
    dataIndex: "MEDCost",
    sorter: (a: { FrameType: string }, b: { FrameType: any }) =>
      a.FrameType?.localeCompare(b.FrameType),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
];

export const PROCESS_KIT_FRAME_COLUMN: ColumnsType<KITS_FRAME_DATA_TYPE> = [
  { title: "Collection", dataIndex: "Collection" },
  { title: "Model", dataIndex: "Model" },
  { title: "Color", dataIndex: "Color" },
  {
    title: "Color Description",
    dataIndex: "ColorDescription",
  },
  {
    title: "Eye Size",
    dataIndex: "EyeSize",
  },
  {
    title: "UPCCode",
    dataIndex: "UPC",
  },
  { title: "Frame Type", dataIndex: "FrameType" },
  {
    title: "MED Cost",
    dataIndex: "MEDCost",
  },
];

export const PROCESS_KIT_OFFICES_COLUMN = [
  { title: "LocationNumber", dataIndex: "LocationNumber" },
  { title: "Quantity", dataIndex: "Quantity" },
];

export const AVIALBLE_OFFICE_COLUMN: ColumnsType<OFFICE_DATA_TYPE> = [
  {
    title: "Available Offices",
    dataIndex: "LocationNumber",
    filterMultiple: true,
  },
];
export const SELECTED_OFFICES_COLUMN: ColumnsType<OFFICE_DATA_TYPE> = [
  {
    title: "selected Offices",
    dataIndex: "LocationNumber",
    filterMultiple: true,
  },
];

export const FRAME_COLLECTION_LISTING_COLUMN: ColumnsType<COLLECTION_LISTING_DATA_TYPE> =
  [
    {
      title: "Assortment ID",
      dataIndex: "AssortmentId",
      filterMultiple: true,
      sorter: (a, b) => a.AssortmentId - b.AssortmentId,
      sortDirections: ["ascend", "descend"],
      width: 110,
      align: "center",
    },
    {
      title: "Assortment Name",
      dataIndex: "AssortmentName",
      filterMultiple: true,
      sorter: (a, b) =>
        a.AssortmentName?.localeCompare(b.AssortmentName?.localeCompare),
      sortDirections: ["ascend", "descend"],
      width: 110,
      align: "center",
    },
    {
      title: "Database Collection ID",
      dataIndex: "DatabaseCollection",
      filterMultiple: true,
      sorter: (a, b) => a.DatabaseCollection - b.DatabaseCollection,
      sortDirections: ["ascend", "descend"],
      width: 110,
      align: "center",
    },
    {
      title: "Frame Type ID",
      dataIndex: "FrameTypeID",
      filterMultiple: true,
      sorter: (a, b) => a.FrameTypeID - b.FrameTypeID,
      sortDirections: ["ascend", "descend"],
      width: 90,
      align: "center",
    },
    {
      title: "Assortment Quantity",
      dataIndex: "ArrangerQty",
      sorter: (a: { ArrangerQty: any }, b: { ArrangerQty: any }) =>
        a.ArrangerQty - b.ArrangerQty,
      sortDirections: ["ascend", "descend"],
      width: 100,
      align: "center",
    },
    {
      title: "Assortment Count",
      dataIndex: "AssortmentCount",
      sorter: (a: { AssortmentCount: any }, b: { AssortmentCount: any }) =>
        a.AssortmentCount - b.AssortmentCount,
      sortDirections: ["ascend", "descend"],
      width: 100,
      align: "center",
    },
    {
      title: "SKU",
      dataIndex: "SKU",
      width: 60,
      render: (text: string, record): ReactNode => {
        return (
          <Links
            AssortmentId={record?.AssortmentId}
            modalTitle={"SKUs in Assortment"}
          />
        );
      },
    },
    {
      title: "DEPRECATED (Count per Collection)",
      dataIndex: "CountperCollection",
      sorter: (
        a: { CountperCollection: any },
        b: { CountperCollection: any }
      ) => parseInt(a.CountperCollection) - parseInt(b.CountperCollection),
      sortDirections: ["ascend", "descend"],
      width: 100,
      align: "center",
    },
    {
      title: "Assortment Start Date",
      dataIndex: "StartDate",
      filterMultiple: true,
      sorter: (a, b) =>
        moment(a.StartDate || 0).unix() - moment(b.StartDate || 0).unix(),
      sortDirections: ["ascend", "descend"],
      render: (text: string) => {
        return text ? moment(text).format(DATE_FORMAT) : "N/A";
      },
      width: 120,
      align: "center",
    },
    {
      title: "Assortment Stop Date",
      dataIndex: "StopDate",
      filterMultiple: true,
      sorter: (a, b) =>
        moment(a.StopDate || 0).unix() - moment(b.StopDate || 0).unix(),
      sortDirections: ["ascend", "descend"],
      render: (text: string) => {
        return text ? moment(text).format(DATE_FORMAT) : "N/A";
      },
      width: 125,
      align: "center",
    },
  ];

export const VENDOR_COLLECTION_LISTING_COLUMN: ColumnsType<VENDOR_COLLECTION_LISTING_DATA_TYPE> =
  [
    {
      title: "Database Collection ID",
      dataIndex: "DatabaseCollectionID",
      filterMultiple: true,
      sorter: (a, b) => a.DatabaseCollectionID - b.DatabaseCollectionID,
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Acuity Collection ID",
      dataIndex: "AcuityCollectionID",
      filterMultiple: true,
      sorter: (a, b) => a.AcuityCollectionID - b.AcuityCollectionID,
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Acuity Vendor ID",
      dataIndex: "AcuityVendorID",
      filterMultiple: true,
      sorter: (a, b) => a.AcuityVendorID - b.AcuityVendorID,
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Notes",
      dataIndex: "Notes",
      filterMultiple: true,
      width: 120,
      align: "center",
    },
  ];

export const OFFICE_COLLECTION_LISTING_COLUMN: ColumnsType<OFFICE_COLLECTION_LISTING_DATA_TYPE> =
  [
    {
      title: "Office Number",
      dataIndex: "OfficeNum",
      filterMultiple: true,
      sorter: (a: { OfficeNum: string }, b: { OfficeNum: any }) =>
        a.OfficeNum?.localeCompare(b.OfficeNum),
      sortDirections: ["ascend", "descend"],
      width: 105,
      align: "center",
    },
    {
      title: "Office Name",
      dataIndex: "LocationName",
      filterMultiple: true,
      sorter: (a, b) => a.LocationName?.localeCompare(b.LocationName),
      sortDirections: ["ascend", "descend"],
      width: 105,
      align: "center",
    },
    {
      title: "Assortment ID",
      dataIndex: "FrameArrangerID",
      filterMultiple: true,
      sorter: (a, b) => a.FrameArrangerID - b.FrameArrangerID,
      sortDirections: ["ascend", "descend"],
      width: 105,
      align: "center",
    },
    {
      title: "Assortment Name",
      dataIndex: "AssortmentName",
      filterMultiple: true,
      sorter: (a, b) => a.AssortmentName - b.AssortmentName,
      sortDirections: ["ascend", "descend"],
      width: 105,
      align: "center",
    },
    {
      title: "Assortment Quantity",
      dataIndex: "ArrangerQty",
      sorter: (a: { ArrangerQty: any }, b: { ArrangerQty: any }) =>
        a.ArrangerQty - b.ArrangerQty,
      sortDirections: ["ascend", "descend"],
      width: 98,
      align: "center",
    },
    {
      title: "Region",
      dataIndex: "Region",
      filterMultiple: true,
      sorter: (a, b) => a.Region?.localeCompare(b.Region),
      sortDirections: ["ascend", "descend"],
      width: 105,
      align: "center",
    },
    {
      title: "District",
      dataIndex: "District",
      filterMultiple: true,
      sorter: (a, b) => a.District?.localeCompare(b.District),
      sortDirections: ["ascend", "descend"],
      width: 105,
      align: "center",
    },

    {
      title: "Start Date",
      dataIndex: "UpdatedDate",
      sorter: (a, b) =>
        moment(a.UpdatedDate || 0).unix() - moment(b.UpdatedDate || 0).unix(),
      sortDirections: ["ascend", "descend"],
      width: 98,
      align: "center",
      render: (text: string) => {
        return text ? moment(text).format(DATE_FORMAT) : "N/A";
      },
    },
    {
      title: "Changed Notes",
      dataIndex: "UpdateNotes",
      sorter: (a: { UpdateNotes: any }, b: { UpdateNotes: any }) =>
        a.UpdateNotes?.localeCompare(b.UpdateNotes),
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Stop Date",
      dataIndex: "StopDate",
      sorter: (a, b) =>
        moment(a.StopDate || 0).unix() - moment(b.StopDate || 0).unix(),
      sortDirections: ["ascend", "descend"],
      width: 98,
      align: "center",
      render: (text: string) => {
        return text ? moment(text).format(DATE_FORMAT) : "N/A";
      },
    },
  ];

export const PRODUCT_PLANOGRAM_LISTING_COLUMN: ColumnsType<PRODUCT_PLANOGRAM_LISTING_DATA_TYPE> =
  [
    {
      title: "UPC",
      dataIndex: "UPC",
      filterMultiple: true,
      sorter: (a, b) => a.UPC - b.UPC,
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Frame Name",
      dataIndex: "FrameName",
      filterMultiple: true,
      sorter: (a: { FrameName: string }, b: { FrameName: any }) =>
        a.FrameName?.localeCompare(b.FrameName),
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Model",
      dataIndex: "Model",
      filterMultiple: true,
      sorter: (a: { Model: string }, b: { Model: any }) =>
        a.Model?.localeCompare(b.Model),
      sortDirections: ["ascend", "descend"],
      width: 100,
      align: "center",
    },
    {
      title: "Style Name",
      dataIndex: "StyleName",
      filterMultiple: true,
      sorter: (a, b) => a.StyleName - b.StyleName,
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Color Number",
      dataIndex: "ColorNumber",
      filterMultiple: true,
      sorter: (a, b) => a.ColorNumber - b.ColorNumber,
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Color Description",
      dataIndex: "ColorDescription",
      filterMultiple: true,
      sorter: (a: { ColorDescription: string }, b: { ColorDescription: any }) =>
        a.ColorDescription?.localeCompare(b.ColorDescription),
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Eye Size",
      dataIndex: "EyeSize",
      filterMultiple: true,
      sorter: (a, b) => a.EyeSize - b.EyeSize,
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Edge",
      dataIndex: "Edge",
      filterMultiple: true,
      sorter: (a: { Edge: string }, b: { Edge: any }) =>
        a.Edge?.localeCompare(b.Edge),
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "No Replenish Date",
      dataIndex: "NoReplenishmentDate",
      filterMultiple: true,
      sorter: (a, b) =>
        moment(a.NoReplenishmentDate || 0).unix() -
        moment(b.NoReplenishmentDate || 0).unix(),
      sortDirections: ["ascend", "descend"],
      width: 140,
      align: "center",
    },
    {
      title: "Effective Date",
      dataIndex: "EffectiveDate",
      filterMultiple: true,
      sorter: (a, b) =>
        moment(a.EffectiveDate || 0).unix() -
        moment(b.EffectiveDate || 0).unix(),
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Rank",
      dataIndex: "Rank",
      filterMultiple: true,
      sorter: (a, b) => a.Rank - b.Rank,
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
  ];

export const OFFICE_COLLECTION_EXCEL_COLUMNS = [
  {
    header: "Office Number",
    key: "OfficeNum",
    width: 20,
  },
  {
    header: "Office Name",
    key: "LocationName",
    width: 20,
  },
  {
    header: "Assortment ID",
    key: "FrameArrangerID",
    width: 20,
  },
  {
    header: "Assortment Name",
    key: "AssortmentName",
    width: 20,
  },
  {
    header: "Assortment Quantity",
    key: "ArrangerQty",
    width: 30,
  },
  {
    header: "Vendor Name",
    key: "Vendor",
    width: 20,
  },
  {
    header: "Collection Name",
    key: "Collection",
    width: 20,
  },
  {
    header: "Collection Type",
    key: "CollectionType",
    width: 20,
  },
  {
    header: "Start Date",
    key: "UpdatedDate",
    width: 20,
    align: "center",
  },
  {
    header: "Changed Notes",
    key: "UpdateNotes",
    width: 20,
  },
  {
    header: "Stop Date",
    key: "StopDate",
    width: 20,
    align: "center",
  },
];

export const ACCESSORY_REPLENISHMENT_EXCEL_COLUMNS = [
  {
    header: "Product",
    key: "ItemName",
    width: 20,
  },
  {
    header: "UPC Code",
    key: "SKU",
    width: 20,
  },
  {
    header: "Replenisment Type",
    key: "ReplenishmentType",
    width: 20,
  },
  {
    header: "Trigger Quantity",
    key: "ReplenishThreshold",
    width: 20,
  },
  {
    header: "Order Quantity",
    key: "OrderQuantity",
    width: 20,
  },

  {
    header: "On Hand Quantity",
    key: "CurrentOnHandQuantity",
    width: 20,
  },
  {
    header: "Stocked By DC",
    key: "IsStockedAtDC",
    width: 20,
    align: "center",
  },
  {
    header: "Office",
    key: "LocationID",
    width: 20,
  },
  {
    header: "Consider Backorder",
    key: "IsConsiderBackorder",
    width: 20,
  },
  {
    header: "Current Sales Volume",
    key: "SalesAfterLastReplenishDate",
    width: 20,
  },
  {
    header: "Last Replenishment Date",
    key: "LastReplenishmentDate",
    width: 20,
  },
];

export const PRODUCT_PLANOGRAM_EXCEL_COLUMNS = [
  {
    header: "Product Planogram ID",
    key: "ID1",
    width: 20,
  },
  {
    header: "Frame Arranger ID",
    key: "FrameArrangerID",
    width: 20,
  },
  {
    header: "Model",
    key: "Model",
    width: 20,
  },
  {
    header: "Style Name",
    key: "StyleName",
    width: 20,
  },
  {
    header: "Vendor",
    key: "Vendor",
    width: 20,
  },

  {
    header: "Collection",
    key: "Collection",
    width: 20,
  },
  {
    header: "Color Number",
    key: "ColorNumber",
    width: 20,
    align: "center",
  },
  {
    header: "Color Description",
    key: "ColorDescription",
    width: 20,
  },
  {
    header: "Eye Size",
    key: "EyeSize",
    width: 20,
  },
  {
    header: "Bridge",
    key: "Bridge",
    width: 20,
  },
  {
    header: "Temple",
    key: "Temple",
    width: 20,
  },
  {
    header: "A",
    key: "A",
    width: 20,
  },
  {
    header: "B",
    key: "B",
    width: 20,
  },
  {
    header: "ED",
    key: "ED",
    width: 20,
  },
  {
    header: "Material",
    key: "Material",
    width: 20,
  },
  {
    header: "Edge",
    key: "Edge",
    width: 20,
  },
  {
    header: "UPC",
    key: "UPC",
    width: 20,
  },
  {
    header: "SKUQty",
    key: "SKUQty",
    width: 20,
  },
  {
    header: "FrameType",
    key: "FrameType",
    width: 20,
  },
  {
    header: "Gender",
    key: "Gender",
    width: 20,
  },
  {
    header: "Frame Name",
    key: "FrameName",
    width: 20,
  },
  {
    header: "MED Cost",
    key: "MEDCost",
    width: 20,
  },
  {
    header: "Wholesale",
    key: "Wholesale",
    width: 20,
  },
  {
    header: "Retail",
    key: "Retail",
    width: 20,
  },
  {
    header: "Recall Date",
    key: "RecallDate",
    width: 20,
  },
  {
    header: "ItemGroup",
    key: "ItemGroup",
    width: 20,
  },
  {
    header: "NoReplenishDate",
    key: "NoReplenishmentDate",
    width: 20,
  },
  {
    header: "Effective Date",
    key: "EffectiveDate",
    width: 20,
  },
  {
    header: "Phasing",
    key: "PhasingInStyle",
    width: 20,
  },
  {
    header: "Min",
    key: "Min",
    width: 20,
  },
  {
    header: "Max",
    key: "Max",
    width: 20,
  },
  {
    header: "Stocked At DC",
    key: "StockedAtDC",
    width: 20,
  },
  {
    header: "LiveCost",
    key: "LiveCost",
    width: 20,
  },
  {
    header: "LiveWholeSale",
    key: "LiveWholeSale",
    width: 20,
  },
  {
    header: "LiveRetail",
    key: "LiveRetail",
    width: 20,
  },
  {
    header: "Frame Color Family",
    key: "FrameColorFamily",
    width: 20,
  },
  {
    header: "Frame Shape",
    key: "FrameShape",
    width: 20,
  },
  {
    header: "Age Group",
    key: "AgeGroup",
    width: 20,
  },
  {
    header: "Rank",
    key: "Rank",
    width: 20,
  },
  {
    header: "Receipt Date",
    key: "ReceiptDate",
    width: 20,
  },
  {
    header: "Item Type",
    key: "ItemType",
    width: 20,
  },
  {
    header: "Life Cycle Status",
    key: "LifeCycleStatus",
    width: 20,
  },
  {
    header: "Life Cycle Status Date",
    key: "LifeCycleStatusDate",
    width: 20,
  },
  {
    header: "SKU Grade",
    key: "SKUGrade",
    width: 20,
  },
];

export const FEDEX_OFFICE_COLUMN: ColumnsType<any> = [
  {
    title: "Office ID",
    dataIndex: "OfficeNumber",
    filterMultiple: true,
    sorter: (a: { OfficeNumber: string }, b: { OfficeNumber: any }) =>
      a.OfficeNumber?.localeCompare(b.OfficeNumber),
    sortDirections: ["ascend", "descend"],
    width: 90,
    align: "center",
  },
  {
    title: "Office Name",
    dataIndex: "OfficeName",
    filterMultiple: true,
    sorter: (a, b) => a.OfficeName?.localeCompare(b.OfficeName),
    sortDirections: ["ascend", "descend"],
    width: 90,
    align: "center",
  },
  {
    title: "Address",
    dataIndex: "Address",
    filterMultiple: true,
    width: 180,
    align: "center",
  },
  {
    title: "Phone",
    dataIndex: "PhoneNumber",
    filterMultiple: true,
    width: 85,
    align: "center",
  },
  {
    title: "Transaction Number",
    dataIndex: "TransactionId",
    filterMultiple: true,
    width: 105,
    align: "center",
  },
  {
    title: "Tracking Number",
    dataIndex: "TrackingNumber",
    //filterMultiple: true,
    width: 98,
    align: "center",
  },
  {
    title: "Service Type",
    dataIndex: "ServiceType",
    //filterMultiple: true,
    width: 98,
    align: "center",
  },
  {
    title: "Expected Delivery Date",
    dataIndex: "ExpectedDeliveryDate",
    //filterMultiple: true,
    width: 98,
    align: "center",
    render: (text: string) => {
      return text ? moment(text).format(DATE_FORMAT) : "N/A";
    },
  },
  {
    title: "Estimated cost",
    dataIndex: "EstimatedCost",
    //filterMultiple: true,
    width: 98,
    align: "center",
    render: (text: any) => {
      return "$" + text;
    },
  },
];
const DragHandle = SortableHandle(() => (
  <HolderOutlined style={{ cursor: "grab", color: "#999" }} />
));
export const PP_COLUMNS: ColumnsType<any> = [
  {
    title: "Column Name",
    dataIndex: "title",
    className: "drag-visible",
  },
  {
    title: "",
    dataIndex: "dragger",
    align: "right",
    render: () => <DragHandle />,
    className: "drag-visible",
  },
];

export const PRODUCT_PLANOGRAM_DRAWER_COLUMN = [
  {
    title: "Product Planogram ID",
    dataIndex: "ID1",
    filterMultiple: true,
    sorter: (a: { ID1: number }, b: { ID1: number }) => a.ID1 - b.ID1,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "FrameArranger ID",
    dataIndex: "FrameArrangerID",
    filterMultiple: true,
    sorter: (a: { FrameArrangerID: number }, b: { FrameArrangerID: number }) =>
      a.FrameArrangerID - b.FrameArrangerID,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "SKU Qty",
    dataIndex: "SKUQty",
    filterMultiple: true,
    sorter: (a: { SKUQty: number }, b: { SKUQty: number }) =>
      a.SKUQty - b.SKUQty,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Bridge",
    dataIndex: "Bridge",
    filterMultiple: true,
    sorter: (a: { Bridge: number }, b: { Bridge: number }) =>
      a.Bridge - b.Bridge,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Temple",
    dataIndex: "Temple",
    filterMultiple: true,
    sorter: (a: { Temple: number }, b: { Temple: number }) =>
      a.Temple - b.Temple,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "A",
    dataIndex: "A",
    filterMultiple: true,
    sorter: (a: { A: number }, b: { A: number }) => a.A - b.A,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "B",
    dataIndex: "B",
    filterMultiple: true,
    sorter: (a: { B: number }, b: { B: number }) => a.B - b.B,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "ED",
    dataIndex: "ED",
    filterMultiple: true,
    sorter: (a: { ED: number }, b: { ED: number }) => a.ED - b.ED,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },

  {
    title: "MED Cost",
    dataIndex: "MEDCost",
    filterMultiple: true,
    sorter: (a: { MEDCost: number }, b: { MEDCost: number }) =>
      a.MEDCost - b.MEDCost,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Wholesale",
    dataIndex: "Wholesale",
    filterMultiple: true,
    sorter: (a: { Wholesale: number }, b: { Wholesale: number }) =>
      a.Wholesale - b.Wholesale,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Retail",
    dataIndex: "Retail",
    filterMultiple: true,
    sorter: (a: { Retail: string }, b: { Retail: any }) =>
      a.Retail?.localeCompare(b.Retail),
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Recall Date",
    dataIndex: "RecallDate",
    filterMultiple: true,
    sorter: (a: { RecallDate: any }, b: { RecallDate: any }) =>
      moment(a.RecallDate || 0).unix() - moment(b.RecallDate || 0).unix(),
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Phasing In Style",
    dataIndex: "PhasingInStyle",
    filterMultiple: true,
    sorter: (a: { PhasingInStyle: number }, b: { PhasingInStyle: number }) =>
      a.PhasingInStyle - b.PhasingInStyle,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Min",
    dataIndex: "Min",
    filterMultiple: true,
    sorter: (a: { Min: number }, b: { Min: number }) => a.Min - b.Min,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Max",
    dataIndex: "Max",
    filterMultiple: true,
    sorter: (a: { Max: number }, b: { Max: number }) => a.Max - b.Max,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Stocked at DC",
    dataIndex: "StockedAtDC",
    filterMultiple: true,
    sorter: (a: { StockedAtDC: number }, b: { StockedAtDC: number }) =>
      a.StockedAtDC - b.StockedAtDC,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Live Cost",
    dataIndex: "LiveCost",
    filterMultiple: true,
    sorter: (a: { LiveCost: number }, b: { LiveCost: number }) =>
      a.LiveCost - b.LiveCost,
    sortDirections: ["ascend", "descend"],
    width: 130,
    align: "center",
  },
  {
    title: "Live WholeSale",
    dataIndex: "LiveWholeSale",
    filterMultiple: true,
    sorter: (a: { LiveWholeSale: number }, b: { LiveWholeSale: number }) =>
      a.LiveWholeSale - b.LiveWholeSale,
    sortDirections: ["ascend", "descend"],
    width: 130,
    align: "center",
  },
  {
    title: "Live Retail",
    dataIndex: "LiveRetail",
    filterMultiple: true,
    sorter: (a: { LiveRetail: number }, b: { LiveRetail: number }) =>
      a.LiveRetail - b.LiveRetail,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Receipt Date",
    dataIndex: "ReceiptDate",
    filterMultiple: true,
    sorter: (a: { ReceiptDate: any }, b: { ReceiptDate: any }) =>
      moment(a.ReceiptDate || 0).unix() - moment(b.ReceiptDate || 0).unix(),
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Item Type",
    dataIndex: "ItemType",
    filterMultiple: true,
    sorter: (a: { ItemType: number }, b: { ItemType: number }) =>
      a.ItemType - b.ItemType,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Life Cycle Status Date",
    dataIndex: "LifeCycleStatusDate",
    filterSearch: true,
    sorter: (
      a: { LifeCycleStatusDate: any },
      b: { LifeCycleStatusDate: any }
    ) =>
      moment(a.LifeCycleStatusDate || 0).unix() -
      moment(b.LifeCycleStatusDate || 0).unix(),
    sortDirections: ["ascend", "descend"],
    render: (text: string) => {
      return text ? moment(text).format(DATE_FORMAT) : "N/A";
    },
    width: 120,
    align: "center",
  },
];

export const ASSORTMENT_SKU_COLUMN: ColumnsType<any> = [
  {
    title: "UPC",
    dataIndex: "UPC",
    filterMultiple: true,
    sorter: (a: { UPC: number }, b: { UPC: number }) => a.UPC - b.UPC,
    sortDirections: ["ascend", "descend"],
    width: 30,
    align: "center",
  },
  {
    title: "Model",
    dataIndex: "Model",
    filterMultiple: true,
    sorter: (a: { Model: string }, b: { Model: string }) =>
      a.Model?.localeCompare(b.Model),
    sortDirections: ["ascend", "descend"],
    width: 20,
    align: "center",
  },
  {
    title: "Color",
    dataIndex: "Color",
    filterMultiple: true,
    sorter: (a: { Color: string }, b: { Color: string }) =>
      a.Color?.localeCompare(b.Color),
    sortDirections: ["ascend", "descend"],
    width: 20,
    align: "center",
  },
  {
    title: "Color Description",
    dataIndex: "ColorDescription",
    filterMultiple: true,
    sorter: (
      a: { ColorDescription: string },
      b: { ColorDescription: string }
    ) => a.ColorDescription?.localeCompare(b.ColorDescription),
    sortDirections: ["ascend", "descend"],
    width: 50,
    align: "center",
  },
  {
    title: "Eye Size",
    dataIndex: "EyeSize",
    filterMultiple: true,
    sorter: (a: { EyeSize: string }, b: { EyeSize: string }) =>
      a.EyeSize?.localeCompare(b.EyeSize),
    sortDirections: ["ascend", "descend"],
    width: 20,
    align: "center",
  },
  {
    title: "Frame Type",
    dataIndex: "FrameType",
    filterMultiple: true,
    sorter: (a: { FrameType: string }, b: { FrameType: string }) =>
      a.FrameType?.localeCompare(b.FrameType),
    sortDirections: ["ascend", "descend"],
    width: 20,
    align: "center",
  },
  {
    title: "MED Cost",
    dataIndex: "MEDCost",
    filterMultiple: true,
    sorter: (a: { MEDCost: string }, b: { MEDCost: string }) =>
      a.MEDCost?.localeCompare(b.MEDCost),
    sortDirections: ["ascend", "descend"],
    width: 20,
    align: "center",
  },
];
const columns: any = {
  OPEN_PULL_COLUMN,
  FRAME_PICK_COLUMN,
  DC_REPLENISHMENT_COLUMN,
  ORDER_STATUS_COLUMN,
};

export default columns;
