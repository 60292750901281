import { useEffect, useState } from "react";
import { Modal, Table } from "antd";
import styled from "styled-components";
import "../OfficeLocations/officeLocation.scss";
import { ASSORTMENT_SKU_COLUMN } from "src/util/columns";
import { fetchSKUDetails } from "src/components/FrameArranger/FrameArrangerQty/sagas";
import { showErrorNotification } from "src/util/notifications";

interface Props {
  AssortmentId: any;
  modalTitle: any;
}

function Links({ AssortmentId, modalTitle }: Props) {
  const [open, setOpen] = useState(false);
  const [skuData, setSkuData] = useState<any>();

  const handleLink = (id: any) => {
    fetchSKUDetails(AssortmentId)
      .then((res: any) => {
        setSkuData(res);
        setOpen(true);
      })
      .catch((error: any) => {
        showErrorNotification(error.response.data.error.messages[0]);
      });
  };

  return (
    <>
      <StyledLocation>
        <span onClick={handleLink}>SKUs</span>
      </StyledLocation>

      <Modal
        title={modalTitle}
        okText="Ok"
        centered
        width={800}
        bodyStyle={{ overflowY: "scroll", maxHeight: "calc(100vh - 200px)" }}
        open={open}
        onCancel={() => setOpen(false)}
        footer={false}
      >
        <Table
          rowKey="UPC"
          columns={ASSORTMENT_SKU_COLUMN}
          dataSource={skuData}
          scroll={{ y: 500 }}
          pagination={false}
        />{" "}
      </Modal>
    </>
  );
}

const StyledLocation = styled.div`
  color: #40668c;
  text-decoration: underline;
   cursor: pointer;
`;

const StyledItem = styled.div`
  align: "center";
`;

export default Links;
