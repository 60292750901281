import { call, put } from "redux-saga/effects";
import { showErrorNotification } from "src/util/notifications";
import api from "src/util/api";
import { FEDEX_URL, FRAME_PICK_URL } from "src/util/constants/api.constants";
import { fetchFedExFailed, fetchFedExSuccess } from "./actions";

export function* fetchFedEx({ payload }: any): Generator {
  try {
    let url = FEDEX_URL.SHIPPING_STATUS + "/details/" + payload;
    const data: any = yield call(api.get, url);
    yield put(fetchFedExSuccess(data));
  } catch (e: any) {
    yield put(fetchFedExFailed({ message: e }));
    showErrorNotification(null, e);
  }
}

export function getShippingButtonStatus() {
  let url = FEDEX_URL.SHIPPING_STATUS + "/status";
  return api.get(url, {});
}

export function saveShippingButtonStatus(payload: any) {
  let url = FEDEX_URL.SHIPPING_STATUS;
  return api.post(url, payload);
}
export function createFedExShipping(payload: any) {
  let url = FEDEX_URL.CREATE_SHIPPING_URL;
  return api.post(url, payload);
}

export function reprintFedExBarcode(payload: any) {
  let url = FEDEX_URL.REPRINT_URL;
  return api.post(url, payload);
}

export function printEndOfDayReport(data: any) {
  try {
    const response: any = api.post(FRAME_PICK_URL.GENERATE_REPORT, data);
    if (response) {
      return response;
    }
  } catch (error: any) {
    showErrorNotification(error?.response?.data?.error?.messages[0]);
  }
}
