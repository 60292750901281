import { call, put } from "redux-saga/effects";
import {
  fetchProductPlanogramMappingSuccess,
  importFileSuccess,
  importFileFailed,
} from "./actions";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import api from "src/util/api";
import {
  FRAME_ARRANGER_QTY_URL,
  PRODUCT_PLANOGRAM_URL,
} from "src/util/constants/api.constants";

export function* fetchProductPlanogramMapping({ payload }: any): Generator {
  try {
    let url = PRODUCT_PLANOGRAM_URL.PRODUCT_PLANOGRAM_LIST;
    const data: any = yield call(api.get, url, payload);
    yield put(fetchProductPlanogramMappingSuccess(data));
  } catch (e: any) {
    showErrorNotification(null, e);
  }
}

export function saveProductPlanogramMapping(payload: any) {
  let url = `${PRODUCT_PLANOGRAM_URL.PRODUCT_PLANOGRAM_UPDATE_INSERT}`;
  return api.post(url, payload);
}

export function getProductPlanogramMapping() {
  let url = `${PRODUCT_PLANOGRAM_URL.PRODUCT_PLANOGRAM_LIST}`;
  return api.get(url);
}

export function getProductPlanogram(payload: any) {
  let url = PRODUCT_PLANOGRAM_URL.PRODUCT_PLANOGRAM_BY_ID + payload;
  return api.get(url);
}

export function* importFileProductPlanogram({
  payload,
  actionType,
}: any): Generator {
  try {
    let url = `${PRODUCT_PLANOGRAM_URL.PRODUCT_PLANOGRAM_IMPORT_FILE}`;
    const formdata = new FormData();
    formdata.append("file", payload.file);
    const data: any = yield call(api.post, url, formdata);
    yield put(importFileSuccess(data));
  } catch (e: any) {
    yield put(importFileFailed(e.response?.data));
  }
}

export function deletePPItems(payload: any) {
  let url = PRODUCT_PLANOGRAM_URL.DELETE_PP_ITEM + payload;
  return api.delete(url, payload);
}
