import { DatePicker, Form } from "antd";
import moment from "moment";
import { useState } from "react";
import Label from "../Label/Label";

interface DateRangerPicker {
  dateRange: any;
  setDateRange: any;
  memoizeStartDate: any;
  memoizeEndDate: any;
  spinning: any;
}
function DateRangerPicker({
  dateRange,
  setDateRange,
  memoizeStartDate,
  memoizeEndDate,
  spinning,
}: DateRangerPicker) {
  const startDateChange = (date: any) => {
    if (!date) {
      setDateRange({ endDate: date, startDate: date });
    } else {
      memoizeStartDate = date;
      setDateRange({ ...dateRange, startDate: date });
    }
  };
  const endDateChange = (date: any) => {
    memoizeEndDate = date;
    setDateRange({ ...dateRange, endDate: date });
  };
  const { startDate, endDate }: any = dateRange;

  const disabledDate = (current: any) => {
    if (!dateRange) {
      return false;
    }
    const tooLate =
      startDate &&
      (current?.diff(startDate, "days") >= 7 || current?.isAfter(moment()));
    const tooEarly =
      endDate &&
      //?.diff(current, "days") >= 0 &&
      current?.isBefore(moment());
    return !!tooEarly || !!tooLate;
  };

  return (
    <>
      <Label label={"From Date"}>
        <DatePicker
          value={startDate && moment(startDate)}
          disabled={spinning}
          disabledDate={(d) => !d || d.isAfter(moment()) || d.isAfter(endDate)}
          placeholder="Start Date"
          onChange={startDateChange}
          className="search-field-size"
          size="middle"
          style={{ width: 120 }}
        />
      </Label>
      <Label label={"To Date"}>
        <DatePicker
          value={endDate && moment(endDate)}
          disabled={spinning || !startDate}
          disabledDate={disabledDate}
          placeholder="End Date"
          onChange={endDateChange}
          className="search-field-size"
          size="middle"
          style={{ width: 120 }}
        />
      </Label>
    </>
  );
}
export default DateRangerPicker;
